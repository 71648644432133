/** @format */

import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import NftOneCard from "./NftOneCard";
// @ts-ignore
import Wildwest from '../assets/avatars/videos/Monsoon.mp4'; import Winter from '../assets/avatars/videos/Winter.mp4'; import Summer from '../assets/avatars/videos/Summer.mp4'; import Space from '../assets/avatars/videos/Science.mp4';
import PartlyCloudy from '../assets/images/PartlyCloudy.png'; import Lightning from '../assets/images/Lightning.png'; import Snowing from '../assets/images/Snowing.png'; import Raining from '../assets/images/Raining.png';
import lock from '../assets/images/lock.png';

import "./styles.css";
import SwiperBar from "./SwiperBar";
import { texts } from "../Components/LoginComponent/texts";
import AppContext from "Contexts/AppContext";
import SetsScreen from "./SetsScreen";
import { db, firestore } from "../firebase";
import { doc, getDocs, query, where, collection } from "firebase/firestore";
import NftLodding from "./NftLodding";
import { debounce } from "lodash";


const GalleryType = styled.div`
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  // justify-content:space-around;
  justify-content:${window.screen.width > 767 ? "space-between" : "center"};
  color: black;
  & div {
    // border: 1px solid #5f4ce3;
    width:${window.screen.width < 767 ? "80%" : "340px"};
    height:${window.screen.width < 767 ? "91px" : "100px"};
    // height:71px;
    margin: 10px 0px;
     
    cursor:pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
    // padding: 20px 20px;
    text-align:center;
    $ p {
    }
  }
`;
const SummerCard = styled.div`
  display: flex;
  justify-content: center;
  // border:1px solid red;
  flex-wrap: wrap;
  // background-color: #f8f9fa;
`;
const Colortext = styled.p`
font-size:15px;
    background: -webkit-linear-gradient(270deg, #FEFEFE 35.94%, #3C1ABA 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
const NFTGallery = () => {
  const navigate = useNavigate();
  const [collectionType, setCollectionType] = useState<any>()
  const { albumOpen, setAlbumOpen } = useContext(AppContext);
  const [allCardNew, setAllCardNew] = useState<any>([])
  const [allCards, setAllCards] = useState<any>([])
  const [cardType, setCardType] = useState<any>('all')
  const [searchTerm, setSearchTerm] = useState<any>('')
  const [selectCollection, setSelectCollection] = useState<any>('none')
  const [backCards, setBackCards] = useState<any>([]);
  const [equalPart, setEqualPart] = useState<any>([]);
  const [cardShow, setCardShow] = useState<any>(false);
  const [setsCardId, setSetsCardId] = useState<any>('none')
  const [setsValue, setSetsValue] = useState<any>([])
  const [setsCardName, setSetsCardName] = useState<any>('none')
  const [cardNameNew, setCardNameNew] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false);
  const [TypeWather, setTypeWather] = useState<any>(
    [
      { name: "Partly Cloudy", image: PartlyCloudy },
      { name: "Raining", image: Raining },
      { name: "Snowing", image: Snowing },
      { name: "Lightning", image: Lightning }
    ]
  )
  const parameters = new URLSearchParams(window.location.search);
  let tempFilter = allCards;
  const nftGalleryCollectionRef = collection(firestore, 'nftGallery');

  const fetchData = async () => {
    try {
      const snapshot = await getDocs(nftGalleryCollectionRef);

      const data = snapshot.docs.map((doc: any) => ({
        id: doc.id,
        ...doc.data()
      }));
      console.log(data, "snapshot.docs")
      setCollectionType(data);
      // setSelectCollection(albumOpen);
      // setSetsValue([]);
      setCardShow(false);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    // if (albumOpen !== "") {
    fetchData();
    setAlbumOpen("");
    // }
  }, [albumOpen]);

  const getCardDetails = async (albumName: any) => {
    setIsLoading(true);
    if (albumName) {
      try {
        const getCollectionTypeRef = collection(firestore, 'cardsDetails')
        const getOneCollection = query(getCollectionTypeRef, where('albumName', '==', albumName));
        const querySnapshot = await getDocs(getOneCollection);

        const data: any = [];
        querySnapshot.forEach((doc) => {
          data.push({ id: doc.id, ...doc.data() });
        });

        // Sorting by albumName and setName
        data.sort((a: any, b: any) => a.albumName.localeCompare(b.albumName) || a.setName.localeCompare(b.setName));
        setAllCards(data)
        setAllCardNew(data);
        setSelectCollection(albumName);
        setSetsCardId("none")
        setSetsValue([]);
        setIsLoading(false);
        // setCardNameNew('none');
        setCardType('all');
        setSetsCardName('none');

      } catch (error) {
        console.error('Error fetching card details:', error);
        setIsLoading(false);
      }
    } else {
      setSetsCardId("none")
      setSelectCollection(albumName);
      setSetsValue([]);
      setIsLoading(false);
      // setCardNameNew('none');
      setCardType('all');
      setSetsCardName('none');
    }
  };

  useEffect(() => {
    // if (parameters.get('collectionName')) {
    //   handleBacktoModule(parameters.get('collectionName'))
    // }
    if (albumOpen !== "") {
      handleBacktoModule(albumOpen)
      setAlbumOpen("");
    }
  }, [albumOpen]);

  const handleBacktoModule = async (collectionName: any) => {
    // getCardDetails(collectionName);                
    try {
      const getCollectionTypeRef = collection(firestore, 'cardsDetails')
      const getOneCollection = query(getCollectionTypeRef, where('albumName', '==', collectionName));
      const querySnapshot = await getDocs(getOneCollection);

      const data: any = [];
      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });

      // Sorting by albumName and setName
      data.sort((a: any, b: any) => a.albumName.localeCompare(b.albumName) || a.setName.localeCompare(b.setName));

      console.log('I am working', data);

      setAllCards(data)
      setAllCardNew(data);
      setSelectCollection(collectionName);
      setSetsValue([]);
      setIsLoading(false);
      setCardType('all');
      setSetsCardName('none');
    } catch (error) {
      console.error('Error fetching card details:', error);
    }
  };

  const onCollectionChange = async (collectionName: any) => {
    if (searchTerm?.length || selectCollection != "none") {
      setCardShow(true)
    }
    else {
      setCardShow(false)
    }
    try {
      const getAlbumId = collectionType && collectionType?.find((item: any) => item.albumName === collectionName);

      if (getAlbumId) {
        setIsLoading(true);

        const getSetsTypeRef = collection(
          firestore,
          'nftGallery',
          getAlbumId.id,
          'setDetails'
        );

        const querySnapshot = await getDocs(getSetsTypeRef);

        const data: any = [];
        querySnapshot.forEach((doc) => {
          data.push({ id: doc.id, ...doc.data() });
        });

        setIsLoading(false);
        setSetsValue(data);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error fetching sets type:', error);
    }
  };

  useEffect(() => {
    onCollectionChange(selectCollection)
  }, [selectCollection])


  const BackSideCard = (value: string | number) => {
    // @ts-ignore
    if (backCards.includes(value)) {
      let allBackCard = [...backCards];
      allBackCard.splice(backCards.indexOf(value), 1);
      setBackCards(allBackCard)
    }
    else {
      setBackCards([...backCards, value])
    };
  };


  function sliceDived(arr: any, partSize: any) {

    const res = [];
    for (let i = 0; i < arr.length; i += partSize) {
      const DivideEqual = arr?.slice(i, i + partSize);
      res.push(DivideEqual);
    }
    // return res;

    setEqualPart(res)

  }



  useEffect(() => {
    if (allCardNew?.length > 0) {
      sliceDived(allCardNew, 4)

    }
    else {
      sliceDived(allCardNew, 4)
      // setCardShow(false)
    }
  }, [allCardNew])

  const getSno = (Snumber: any) => {
    console.log(Snumber.slice(0, -2), "Snumber")
  }



  console.log(allCardNew, "allCardNewcheck")

  useEffect(() => {

    if (selectCollection == 'none' && searchTerm !== '') {
      setIsLoading(true);
      debouncedSearch(searchTerm.toLowerCase());
    } else {
      if (selectCollection !== 'none') {
        tempFilter = tempFilter.filter((value: any) => value?.albumName.toLowerCase() === selectCollection.toLowerCase());
        console.log(selectCollection, tempFilter, "collectionValue 1")
      }
      if (setsCardId !== 'none') {
        tempFilter = tempFilter.filter((value: any) => value?.setId === setsCardId);
        console.log(tempFilter, "collectionValue 2")
      }

      setCardNameNew([...tempFilter].sort((a: any, b: any) => a.cardName.localeCompare(b.cardName)))

      if (searchTerm !== '') {
        tempFilter = tempFilter.filter((value: any) => value?.cardName.toLowerCase().includes(searchTerm.toLowerCase()));
        console.log(tempFilter, "collectionValue 3")
      }
      if (cardType !== 'all') {
        tempFilter = tempFilter.filter((value: any) => value?.cardType.toLowerCase() === cardType.toLowerCase());
        console.log(tempFilter, "collectionValue 4")
      }
      if (setsCardName !== 'none') {
        tempFilter = tempFilter.filter((value: any) => value?.cardName.toLowerCase() === setsCardName.toLowerCase());
        console.log(tempFilter, "collectionValue 5")
      }
    }

    console.log(tempFilter, "tempFilter")
    setAllCardNew(tempFilter);
    return () => {debouncedSearch.cancel()
      setIsLoading(false);
    };

  }, [searchTerm, selectCollection, setsCardId, cardType, setsCardName]);

  const debouncedSearch = debounce(async (value) => {
    if (value.trim() !== '' && value?.trim()?.length > 2) {
      const capitalizedQuery = value.split(' ').map((word: any) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');;
      const q = query(collection(db, 'cardsDetails'), where('cardName', '>=', capitalizedQuery), where('cardName', '<=', capitalizedQuery + '\uf8ff'));
      const querySnapshot = await getDocs(q);
      const results = querySnapshot.docs.map(doc => doc.data());
      console.log(results, "getresults");
      setCardNameNew([...results].sort((a: any, b: any) => a.cardName.localeCompare(b.cardName)))

      // setAllCards(results)      
      setIsLoading(false);
      setAllCardNew(results);
    } else {
      console.log("not found TropicSip", "getresults");
      setIsLoading(false);
      // setSearchCard([])
    }
  }, 300);

  const handleInputChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  console.log("", "getresults");

  return (
    <div className=''
    // style={{ minHeight: "auto" }}
    >
      <h5 className="mt-4 text-center ">
        <strong style={{ textTransform: 'uppercase', fontSize: "1.26rem" }}>Wall of fame</strong>
      </h5>
      <div className='d-flex justify-content-center mt-2  flex-wrap w-100 py-2 ipad_row_gap'
      >
        <input
          type='text'
          onChange={e => handleInputChange(e)}
          value={searchTerm}
          placeholder='SEARCH...'
          className='py-2 mx-2 color-back '
          style={{ width: "200px" }}

        />
        <div className={`${window.screen.width < 767 ? "py-3 d-flex" : ""}`}>
          <select
            className='color-back py-2 mx-2'
            value={selectCollection}
            onChange={e => {
              // setSelectCollection(e.target.value)
              getCardDetails(e.target.value)
            }}
            style={{
              width: "155px",
              textTransform: "uppercase"
            }}
          >
            <option value='none'>{texts.SelectCollection}</option>


            {collectionType?.map((data: any, index: number) => {
              return <option selected value={data?.albumName} key={index} style={{ textTransform: "uppercase" }} >{data?.albumName}</option>
            })}
          </select>
          <select
            className='color-back py-2 mx-2'
            // onChange={e=>onCollectionChange(e.target.value)}
            onChange={e => setSetsCardId(e.target.value)}
            value={setsCardId}
            style={{
              width: "155px"
            }}
          >
            <option value='none'>{texts.SelectSets}</option>
            {setsValue?.map((data: any, index: number) => {
              // return  <option value={ data?.setId} key={index}>{`${((data?.setName)?.toUpperCase())?.slice(0, 3) + data?.setId}`}</option>        
              return <option selected value={data?.id} key={index}>{`${((data?.setName)?.toUpperCase())}`}</option>
            })}
          </select>
        </div>
        <div className={`${window.screen.width < 767 ? "" : ""} `}>
          <select
            name='type'
            id='type'
            className='  color-back mx-2 py-2'
            onChange={(e) => { setCardType(e.target.value) }}
            value={cardType}
            style={{
              width: "155px",
              textTransform: "uppercase"
            }}
          >
            {selectCollection != "none" ? <><option value='all' style={{ textTransform: "uppercase" }}>{texts.SelectType}</option>
              <option value={`${texts.Legendary}`}>{texts.Legendary}</option>
              <option value={`${texts.Rare}`}>{texts.Rare}</option>
              <option value={`${texts.Epic}`}>{texts.Epic}</option>
              <option value={`${texts.UNCommon}`}>{texts.UNCommon}</option>
              <option value={`${texts.Common}`}>{texts.Common}</option></> :
              <option value='all'>{texts.SelectType}</option>}
          </select>
          <select
            className='color-back py-2 mx-2'
            // onChange={e=>onCollectionChange(e.target.value)}
            onChange={e => setSetsCardName(e.target.value)}
            value={setsCardName}
            style={{
              width: "155px",
              textTransform: "uppercase"
            }}
          >
            <option value='none'>{texts.SelectName}</option>
            {cardNameNew?.map((data: any, index: number) => {
              return <option selected value={data?.cardName} key={index} style={{ textTransform: "uppercase" }}>{`${data?.cardName}`}</option>
            })}
          </select>
        </div>
      </div>

      {/* @ts-ignore */}
      {isLoading && collectionType !== "" && <NftLodding />}

      {console.log(allCardNew, "setsValue")}
      {
        !isLoading && selectCollection !== "none" && setsCardId == "none" && setsCardName == "none" && cardType == "all" && searchTerm == "" && allCardNew.length > 5 && <>
          <div className="w-100 d-flex">
            <div className={`${window.screen.width > 767 ? "" : ""} d-flex justify-content-between flex-wrap`} style={{}}>
              {setsValue.map((item: any, index: number) => {
                return <SetsScreen
                  type="WallOfFame"
                  // onSelectSets={onSelectSets}                  
                  onSelectName={setSetsCardName}
                  allCardNew={allCardNew}
                  setsValue={item}
                />
              })}
            </div>
          </div>
        </>
      }
      {(setsCardId != "none" || setsCardName != "none" || cardType != "all" || searchTerm != "") ? <>
        {allCardNew?.length > 0 ?
          <SummerCard className="mt-4">
            {equalPart ? equalPart?.map((cardPart: any, ind: number) => {
              return <div className='w-100 m-auto mb-4' key={ind}>

                <SwiperBar>
                  {cardPart?.map((item: any, index: number) => {
                    return (
                      <NftOneCard
                        key={index}
                        DivClass={item?.cardType}
                        HeaderText={item?.cardType}
                        HeaderClass={`${item?.cardType}_text`}
                        BackCardName={item?.cardName}
                        Rarity={item?.cardType}
                        Quantity={item?.totalQuantity}
                        holderNo={item?.noOfCardHolders}
                        // cardNo={`${((item?.setName)?.toUpperCase())?.slice(0, 3) + item?.setId}`}
                        // cardNo={item?.sno[index]}
                        // GeneralSerialNo={`${((item.collectionName)?.toUpperCase())?.slice(0, 3) + ((item?.setName)?.toUpperCase())?.slice(0, 3) }`}
                        cardNo={`${((item?.cardName)?.toUpperCase())?.slice(0, 2) + (item?.id)?.slice(0, 2)}`}
                        // GeneralSerialNo={item?.sno && (item?.sno[0])?.replace(/[0-9]/g, '')}
                        GeneralSerialNo={
                          item?.albumName?.toUpperCase()?.slice(0, 2) +
                          item?.setName?.toUpperCase()?.slice(0, 2) +
                          item?.cardType?.toUpperCase()?.slice(0, 2)
                        }
                        Serie={item?.setName || "Set" + (index + 1)}
                        CollectionType={item?.albumName || "LEGENDARY"}

                        userId={item?.setId}
                        // CollectionType={item?.collectionName}
                        // CollectionType={item?.albumId}
                        // Disable={"CardDisebal"}                            
                        cardHeader={`${item?.cardName}`}
                        id={item?.id || item?.cardId}
                        BackSideCard={BackSideCard}
                        fulldata={item}
                        flipCard={backCards?.includes(item?.id)}
                        ImgUrl={item?.cardImageUrl || ""}
                        VideoUrl={item?.cardVideoUrl || ""}
                      />
                    );
                  })}
                </SwiperBar>
              </div>
            })
              : ""
            }
          </SummerCard> :
          <div className="d-flex justify-content-center mt-5" >
            {<p style={{
              color: "black"
            }}> {!isLoading && "Data Not Found"} </p>}
          </div>
        }
      </> :
        <>
          {selectCollection == "none" ?
            <GalleryType className='d-flex galleryRow' style={{ width: `${window.screen.width > 787 ? "800px" : "100%"}` }} >
              {(!cardShow && selectCollection === 'none') && collectionType?.map((data: any, index: number) => {
                return <div className="galleryCol" onClick={() => {
                  // setSelectCollection(data?.albumName)
                  getCardDetails(data?.albumName)
                }} key={index}
                  style={{
                    width: "380px",
                    overflow: "hidden",
                    height: "108px",
                    borderRadius: "10px",
                  }}
                >
                  {data?.albumVideoUrl ?
                    <img src={data?.albumVideoUrl} width={'100%'} height={'100%'} />
                    :
                    <p style={{ color: "white" }}>{data?.albumName}</p>
                  }
                  {/* <p>{data?.collectionName} COLLECTION</p> */}
                </div>
              })}
              {(!cardShow && selectCollection === 'none' && collectionType) && TypeWather?.map((data: any, index: number) => {
                return <div
                  // onClick={() => { setSelectCollection(data?.id) }} key={index}
                  style={{
                    width: "380px",
                    //  height:"150px",             
                    background: `linear-gradient(0deg, rgba(84, 60, 214, 0.85), rgba(84, 60, 214, 0.85)) , url(${data.image})`,
                    borderRadius: "10px",
                    backgroundSize: "cover",
                  }}
                  className="d-flex justify-content-center align-items-center flex-column diff_item_Col"
                >
                  <img src={lock} alt="" width={"30px"} />

                  <Colortext className="mt-2">{data.name.toUpperCase()}</Colortext>
                </div>

              })}
            </GalleryType> : null}
        </>
      }

      {(allCardNew == "" && searchTerm != "") && (setsCardId == "none" && setsCardName && "none" && cardType == "all") &&
        <div className="d-flex justify-content-center mt-5">
          {/* <p style={{
            color: "black"
          }}>Data Not Found</p> */}
        </div>}
    </div>

  );
};

export default NFTGallery;
