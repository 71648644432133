import { Form } from "react-bootstrap";
import React, { FormEvent, useState } from "react";
import { User } from "firebase/auth";
import { Callback } from "../../common/models/utils";
import { useTranslation } from "../../common/models/Dictionary";
import { texts } from "./texts";
import { capitalize, upperCase } from "lodash";
import InputField from "../Atoms/Input/InputField";
import { Buttons } from "../Atoms/Button/Button";
import { User as AuthUser } from "@firebase/auth";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const LoginForm = ({
  callback,
  login,
}: {
  callback: Callback<User>;
  login: (
    e: FormEvent<HTMLFormElement>,
    callback: Callback<AuthUser>
  ) => Promise<void>;
}) => {
  const translate = useTranslation();

  const strings = {
    email: capitalize(translate(texts.email)),
    login: upperCase(translate(texts.login)),
    password: capitalize(translate(texts.password)),
  };

  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Form
      onSubmit={async (e) => {
        e.preventDefault();
        await login(e, callback);
      }}
    >
      <Form.Group className="mb-3 w-100" controlId="login-email">
        <InputField
         className="loginFormInputField"
        style={{color:'var(--blue-violet)',boxShadow:window.screen.width>979?'0px 3px 6px #00000029':''}}
          fullWidth={true}
          type="email"
          placeholder={strings.email}
          name="email"
          required
        />
      </Form.Group>

      <Form.Group className="mb-3 w-100" controlId="login-password"
      style={{
        display:"flex",
        alignItems: 'center',
        backgroundColor:'white',
        borderRadius:'4px',
        paddingRight:'25px',
      }}
      >
        <InputField
        className="loginFormInputField"
          fullWidth={true}
          style={{color:'var(--blue-violet)',border:"none",width:"none",boxShadow:"none"}}
          type={showPassword ? 'text' : 'password'}
          placeholder={strings.password}
          name="password"
          required  
        />
          <span
          onClick={toggleShowPassword}
          style={{
            cursor: 'pointer',
            color:"#6352e8",
          }}
        >
          <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash } />
        </span>
      </Form.Group>
      <Buttons.Primary  fullWidth={true} type="submit">
        {strings.login}
      </Buttons.Primary>
    </Form>
  );
};

export default LoginForm;
