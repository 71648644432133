/** @format */
// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
  HelveticaneueRegularNormalBlack13px,
  PoppinsMediumWhite7px,
  PoppinsNormalGunsmoke10px,
} from "../../styledMixins";
import { getAvatar } from "../../common/models/User";
import Avatar from "./Avatar";
import { Leader } from "../../Contexts/CoinsContext";
import Icon from "../Atoms/Checkbox/Icon";
import AddFollower from "../icons/AddFollower";
import Following from "../icons/Following";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { confirmDelete, confirmDeleteGift } from "../../common/utils/helper";
import { useTranslation } from "../../common/models/Dictionary";
import AppContext from "../../Contexts/AppContext";
import UserContext from "../../Contexts/User";
import { texts } from "../LoginComponent/texts";
// import { handleSoundClick } from "common/utils/SoundClick";
import useSound from "use-sound";
// @ts-ignore
import buttonClick from "../../assets/sounds/buttonClick.mp3";
import BuyerPopup from "Components/Profile/CardTrade/BuyerPopup";
import axios from "axios";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { firestore } from "../../firebase";
import BuyerSellerPopup from "Components/Profile/CardTrade/BuyerSellerPopup";
import { showToast } from "App";
import { ToastType } from "Contexts/Notification";
import NFTTradeCard from "common/NFTCard/NFTTradeCard";
import { Modal } from "react-bootstrap";
import { Buttons } from "Components/Atoms/Button/Button";
import WaitForResponse from "Components/Profile/CardTrade/WaitForResponse";
import { info } from "console";
import CardSalePopup from "Components/Profile/CardTrade/CardSalePopup";

const OverlapGroup1 = styled.div`
  height: 60px;
  position: relative;
  display: flex;
  // padding: 10px 0;
  align-items: center;
  background-color: var(--white);
  border-radius: 6px;
  box-shadow: ${(props: { expanded?: boolean }) =>
    `${props.expanded ? "0 3px 6px #00000029" : "0 3px 6px #00000029"}`};
  margin: 0 auto;
  width: 100%;
  justify-content: space-evenly;
`;

const Component515 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 3px 6px #00000029;
  max-width: 400px;
  margin: auto;
  &.component-51-5.component-51-5-1 {
    margin-top: 12px;
  }
`;

const FlexCol = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 5px;
`;

const UsernameUnique = styled.div`
  ${HelveticaneueRegularNormalBlack13px};

  letter-spacing: 0;
  line-height: 13px;
  white-space: nowrap;
  font-size: 12px;
`;

export const StatusContainer = styled.div`
  display: flex;
  padding: 0 4px;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 43px;
  background-color: var(--blue-violet);
  border-radius: 38px;
`;

export const TradeButton = styled.div`
  display: flex;
  padding: 6px 6px;
  // min-width:100px
  justify-content: center;
  width:50px;
  align-items: center;
  cursor: pointer;
  background-color: var(--blue-violet);
  border-radius: 38px;
`;

const Group4092 = styled(StatusContainer)`
  height: 13px;
  background-color: var(--blue-violet);
`;
const RankValue = styled.span`
  font-size: 10px;
  margin-left: 5px;
  color: #6352e8;
`;

const TradeButtons = styled.button`
  color: white;
  font-size: 12px;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
`;

const Minister = styled.div`
  ${PoppinsMediumWhite7px};
  min-height: 11px;
  margin-bottom: -2px;
  min-width: 33px;
  letter-spacing: 0;
  line-height: 5px;
  white-space: nowrap;
`;

export const TradeText = styled.div`
  ${PoppinsMediumWhite7px};
`;

const Address = styled.p`
  ${PoppinsNormalGunsmoke10px};
  min-height: 17px;
  margin-top: 2px;
  letter-spacing: 0.18px;
  line-height: 15px;
  white-space: nowrap;
  margin-bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ElementsAvatarAImage1 = styled.div`
  width: 43px;
  height: 43px;
  // margin-top: 1px;
  // display: flex;
`;

const Component5031 = styled.div`
  height: 28px;
  margin-top: 3px;
  display: flex;
  padding: 6.2px 3.3px;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 28px;
  background-color: var(--moon-raker);
  border: 1px solid #6352e8;
  border-radius: 14px;
`;
const Componentdiv = styled.div`
  height: 28px;
  margin-top: 3px;
  display: flex;
  padding: 6.2px 3.3px;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 28px;
  // background-color: var(--moon-raker);
  // border: 1px solid #6352e8;
  // border-radius: 14px;
`;

const ViewAll = styled(Link)`
  font: var(--font-style-normal) normal medium 9px / var(--line-spacing-13)
    var(--font-family-poppins);
  font-size: var(--font-size-9);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-6352e8);
  text-align: center;
  padding-left: 12px;
  text-decoration: blink;
  text-transform: uppercase;
`;
export type UserCardProps = {
  leader: Leader;
  checked: boolean;
  setChecked: (c: boolean) => void;
  viewAllLink?: string;
  expanded?: boolean;
  Rank?: number;
  singalCardData?: any;
  getFollwersList?: any;
  approvedCard?: any;
  setApprovedCard?: any;
  cardModalShow?: any;
  setCardModalShow?: any;
  setCoinModelShow?: any;
  editSleerRequest?: any;
};

const UserCard = ({
  leader,
  setChecked,
  checked,
  viewAllLink,
  expanded,
  Rank,
  singalCardData,
  getFollwersList,
  approvedCard,
  setApprovedCard,
  cardModalShow,
  setCardModalShow,
  setCoinModelShow,
  editSleerRequest,
}: UserCardProps) => {
  const translate = useTranslation();
  const location = useLocation();
  const pathname = location.pathname;
  const [openModal, setOpenModal] = useState<any>("");
  const navigate = useNavigate();
  const { setFollowerUserId, seletcardHolderType, setSeletcardHolderType, setLoginRedirectMessage, setLogin } =
    useContext(AppContext);
  const { userInfo, user } = useContext(UserContext);
  const [winnerCard, setWinnerCard] = useState<any>();
  const [buyerRequest, setBuyerRequest] = useState<any>("");
  const [tradeDetails, setTradeDetails] = useState<any>([]);
  const [cardDataValue, setCardDataValue] = useState<any>([]);
  const [openApprovePopup, setOpenApprovePopup] = useState<any>(false);
  const [BuyerCardShow, setBuyerCardShow] = useState(false);
  const [BuyerDetails, setBuyerDetails] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  let paramsID = useParams();
  const { type, id } = paramsID;

  const redirectTab = () => {
    if (leader != undefined && setFollowerUserId != undefined) {
      setFollowerUserId(leader?.userId);
      window.localStorage.setItem("followerId", leader?.userId);
    }

    // @ts-ignore
    if (userInfo?.uid == leader?.userId) {
      navigate("/Profile/mine");
    } else {
      navigate("/followerProfile/mine");
    }
  };

  const [handleSoundClick] = useSound(buttonClick);
  const params = window.location.pathname;

  const getAllRewardsOfUser = async (uid: any) => {
    try {
      const rewardsCollection = collection(firestore, "reward_transactions");
      const rewardsQuery = query(rewardsCollection, where("user", "==", uid));
      const querySnapshot = await getDocs(rewardsQuery);

      var winCards: {
        firstRewardCard: string;
        firstRewardCardCollection: string;
        firstRewardCardId: number;
        firstRewardCardSerialNo: string;
        firstRewardCardType: string;
        secondRewardExtraVotes: number;
        thirdRewardDiamonds: number;
      }[] = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (data?.winData?.firstRewardCardId) {
          winCards.push({ ...data.winData, ...data.transactionTime });
        }
      });

      setWinnerCard(winCards);
    } catch (error) {
      // console.log("getAllRewardsOfUser Error", error);
    }
  };

  //@ts-ignore
  const getPriSerialNo = () => {
    var seriaNo;
    let PriSerialNo = winnerCard?.find((winCard: any, index: number) => {
      if (winCard?.firstRewardCardId == singalCardData?.cardId) {
        seriaNo = winCard?.firstRewardCardSerialNo;
        return "hello";
      }
    });
    return seriaNo;
  };

  useEffect(() => {
    if (winnerCard) {
      getPriSerialNo();
    }
  }, [winnerCard]);

  useEffect(() => {
    // @ts-ignore
    let cardData = JSON.parse(localStorage.getItem("singalCardData"));
    if (cardData) {
      setCardDataValue(cardData);
    }
    // }
  }, []);

  const UpdateBuyerData = async (
    data: any,
    callBack: any,
    isEdit?: any,
    getErrorMessage?: any,
    leaderData?: any,
  ) => {
    let dataPayload = {
      ...data,
      sellerUserId: leaderData?.sellerUserId,
      buyerUserId: leaderData?.buyerUserId,
      cardId: leaderData?.cardId,
      serialNumber: leaderData?.serialNumber,
      sellerUserName: leaderData?.sellerUserName || "",
      lastUpdatedBy: userInfo?.uid,
    }

    console.log(dataPayload, "alldatadataPayload")
    try {
      await axios.put(`/cardTrading/updateOpenTrade/${tradeDetails?.id || tradeDetails?.accountId}`, dataPayload).then((res) => {
        if (res.status === 200) {
          console.log(res, "get full response")
          if (callBack) callBack();
          showToast(res?.data?.message, ToastType.SUCCESS);
          setSeletcardHolderType("Holders");
        }
      }).catch(err => {
        console.log(err)
        // setCardModalShow(false);
        getErrorMessage(err?.response?.data?.message)
      })
    } catch (error) {
      // setCardModalShow(false);
      // @ts-ignore
      getErrorMessage(error?.response?.data?.message)
      console.log(error, "err?.response?.data?.message")
    }
  }

  const submitTradeData = async (
    data: any,
    leaderData: any,
    callBack: any,
    isEdit?: any,
    getErrorMessage?: any
  ) => {
    console.log(
      data,
      leaderData,
      callBack,
      isEdit,
      getErrorMessage,
      "get all props"
    );
    let dataPayload = {
      ...data,
      sellerUserId: leaderData?.sellerUserId || userInfo?.uid,
      buyerUserId: leaderData?.buyerUserId || userInfo?.uid,
      sellerUserName: leaderData?.sellerUserName || userInfo?.userName,
      buyerUserName: leaderData?.buyerUserName || userInfo?.userName,
      cardId: id,
      // serialNumber: getPriSerialNo(),
      serialNumber: leaderData?.serialNumber || "",
      accountId: leaderData?.accountId || leaderData?.id,
      tradeId: leaderData?.accountId ? leaderData?.id : "",
      lastUpdatedBy: userInfo?.uid,
      // data?.transitionType == "ASK" ? leaderData?.sellerUserId == userInfo?.uid ? data?.message : leaderData?.sellerRequest?.message || "",
    };

    const extraValues = {
      sellerRequest: {
        message: dataPayload?.sellerUserId == userInfo?.uid ? data?.message : leaderData?.sellerRequest?.message || "",
        coin: dataPayload?.sellerUserId == userInfo?.uid ? data?.coin : leaderData?.sellerRequest?.coin || 0,
        lastUpdateTime: Date.now(),
        userId: dataPayload?.sellerUserId,
      },
      buyerRequest: {
        message: dataPayload?.buyerUserId == userInfo?.uid ? data?.message : leaderData?.buyerRequest?.message || "",
        coin: dataPayload?.buyerUserId == userInfo?.uid ? data?.coin : leaderData?.buyerRequest?.coin || 0,
        lastUpdateTime: Date.now(),
        userId: dataPayload?.buyerUserId,
      },
    }
    const fullPayload = {
      ...dataPayload, ...extraValues
    }
    console.log({ ...dataPayload, ...extraValues }, isEdit, "alldatadataPayload");
    if (isEdit == true) {
      await axios
        .put(`/cardTrading/updateTrade/${leaderData?.id}`, fullPayload)
        .then((res) => {
          if (res.status === 200) {
            if (callBack) callBack();
            showToast(res?.data?.message, ToastType.SUCCESS);
            setSeletcardHolderType("Holders");

            if (res?.data?.result.status === "APPROVED") {
              setApprovedCard(res?.data?.result);
              setTimeout(() => {
                if (res?.data?.result.sellerUserId == userInfo?.uid) {
                  setCoinModelShow(true);
                }
                if (res?.data?.result.buyerUserId == userInfo?.uid) {
                  setCardModalShow(true);
                }
              }, 300);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          // showToast(err?.response?.data?.message, ToastType.ERROR);
          getErrorMessage(err?.response?.data?.message)
        });
    } else {
      await axios
        .post(`/cardTrading/tradeInitiate`, fullPayload)
        .then((res) => {
          if (res.status === 200) {
            setSeletcardHolderType("Holders");
            if (res?.data?.result.status === "APPROVED") {
              setApprovedCard(res?.data?.result);
              setTimeout(() => {
                if (res?.data?.result.sellerUserId == userInfo?.uid) {
                  setCoinModelShow(true);
                }
                if (res?.data?.result.buyerUserId == userInfo?.uid) {
                  setCardModalShow(true);
                }
              }, 300);
            }

            if (callBack) callBack();
            showToast(res?.data?.message, ToastType.SUCCESS);
          }
        })
        .catch((err) => {
          console.log(err);
          // showToast(err.response.data.message, ToastType.ERROR);
          getErrorMessage(err?.response?.data?.message)
        });
    }
  };

  const getCardDetelist = (type: string, info: any) => {

    try {
      axios
        .get(`/cardTrading/getInitiatedTrade/${userInfo?.uid}/${info?.id}`)
        .then((result: any) => {
          console.log(result?.data?.result, "getallresult");
          if (type == "Approve") {
            // setTradeDetails({ ...info, status: "APPROVED" });              
            const objectValue = result?.data?.result[0];
            console.log(objectValue, "objectValue")
            if (objectValue) {
              setTradeDetails({ ...result?.data?.result[0], coin: info?.coin, status: "APPROVED" });
              setOpenApprovePopup(true)
            } else {
              setTradeDetails({ ...info, status: "APPROVED" });
              setBuyerRequest("sellerDetails");
              console.log({ ...info, status: "APPROVED" }, "approveddating")
            }
            console.log(type, "getApprovetype")
          } else {
            if (result?.data?.result[0]) {
              navigate(`/profile/trade?tabID=3`);
            } else {
              setTradeDetails({ ...info, status: "NEGOTIATE" });
              setBuyerRequest("sellerDetails");
            }
          }
          // setBuyerRequest("sellerDetails");                                  
          getAllRewardsOfUser(info?.sellerUserId || userInfo?.uid);
        })
        .catch((err: any) => { });
    } catch (error) { }
  };
  console.log(tradeDetails, "setTradeDetailsdata")
  // @ts-ignore
  // console.log(
  //   leader?.uid !== userInfo?.uid,
  //   seletcardHolderType == "Buyers",
  //   userInfo?.rewardStatistics?.cards.includes(cardDataValue?.cardName),
  //   "getdatavalue"
  // );

  const editOurTrade = async () => {
    try {
      const response = await axios.get(
        `/cardTrading/getOpenTradeOnTransition/${userInfo?.uid}/BID`
      );
      // setAllTradeList(response?.data?.result)  
      setTradeDetails(response?.data?.result[0])
      setBuyerDetails(false);
      // setBuyerCardShow(true);
      setOpenModal("openCardDetails");
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (tradeInfo: any) => {
    console.log(tradeInfo, "0000");

    let result;
    if (tradeInfo.transitionType == "GIFT") {
      result = await confirmDeleteGift();
    } else {
      result = await confirmDelete();
    }

    if (result.isConfirmed) {
      // Proceed with the delete action
      console.log('Item deleted', tradeInfo);
      const data = {
        accountId: tradeInfo?.buyerData?.id
      }
      axios.post(`/cardTrading/deleteOpenTrade`, data).then((res) => {
        setSeletcardHolderType("Holders");
        showToast(res?.data?.message, ToastType.SUCCESS);
      }).catch((err) => {
        setSeletcardHolderType("Holders");
        showToast(err?.response?.data?.message, ToastType.ERROR);
      });
      // Your delete logic here, e.g., calling an API
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      console.log('Delete action canceled');
    }
  };

  return (
    <>
      <Component515
        style={{ boxShadow: !pathname?.includes("/followers") ? "none" : "" }}
      >
        <OverlapGroup1
          expanded={expanded}
          style={{
            boxShadow: !pathname?.includes("/followers") ? "none" : "",
            height: pathname?.includes("/influencers") ? "75px" : "",
            padding: pathname?.includes("/influencers") ? "10px 0" : "",
          }}
        >
          <ElementsAvatarAImage1
            className=" d-flex justify-content-center align-items-center"
            style={{
              borderRadius: "50px",
              boxShadow: `${leader?.isUserUpgraded ? "1px 0px 5px #FAE481" : ""
                }`,
              backgroundColor: `${leader?.isUserUpgraded ? "#FAE481" : ""}`,
            }}
            onClick={(e) => {
              if (user) {
                redirectTab();
              }
            }}
          >
            <Avatar url={getAvatar(leader)} />
          </ElementsAvatarAImage1>
          <FlexCol>
            <UsernameUnique className="pb-1">
              {leader?.displayName}
            </UsernameUnique>

            {leader?.status && (
              <div className="d-flex align-item center ">
                <Group4092>
                  <Minister>{translate(leader?.status || "")}</Minister>
                  {/* <Minister>{translate(leader?.status?.name || "")}</Minister> */}
                </Group4092>
                {(params == "/influencers" || params == "/") &&
                  Rank &&
                  Rank > 0 && <RankValue>#{Rank}</RankValue>}
              </div>
            )}

            <Address
              style={{
                fontSize: params == "/" ? "7.5px" : "",
              }}
            >
              <span
                style={{
                  margin: params == "/" ? "0px" : "0px 3px",
                }}
              >
                {leader?.subscribers} {translate("Followers")}
              </span>
              <span
                style={{
                  margin: params == "/" ? "0px 4px" : "0px 3px",
                }}
              >
                {leader?.leaders} {translate("Following")}
              </span>
              {expanded && (
                <span
                  style={{
                    margin: params == "/" ? "0px" : "0px 3px",
                  }}
                >
                  {/* {Number(Number(leader.pct * 100).toFixed(2))}&nbsp;Score */}
                  {/* {leader?.influencersScore?.toFixed(2) || 0}&nbsp;Score */}
                  {/* {leader?.score === Math.floor(leader?.score) ? leader?.score  || 0 : (leader?.score).toFixed(2) || 0}&nbsp;Score */}
                  {/* {((leader?.successful || 1) / ((leader?.total || 1) * (leader?.successful || 1)))?.toFixed(2)} */}
                  {(params.includes("singalCard") ? ((leader?.successful || 1) / (leader?.totalVote || 1) * (leader?.successful || 1)) : leader?.influencersScore)?.toFixed(2) || 0}&nbsp;Score
                  {/* {((leader?.successful || 1) / (leader?.total || 1) * (leader?.successful || 1))?.toFixed(2)} */}
                </span>
              )}
            </Address>
          </FlexCol>

          {userInfo?.uid !== (leader?.userId || leader?.uid) ? (
            <Component5031
              style={{ background: checked ? "" : "white" }}
              onClick={() => {
                handleSoundClick();
              }}
            >
              <Icon
                setChecked={setChecked}
                checked={checked}
                iconOff={<AddFollower />}
                iconOn={<Following />}
                name={`leader-${leader.userId}`}
              />
            </Component5031>
          ) : (
            <Componentdiv />
          )}
          {viewAllLink && (
            <div>
              <ViewAll to={viewAllLink}>
                {/* {translate("view all")} */}
                {texts.ViewAll}
              </ViewAll>
            </div>
          )}
        </OverlapGroup1>
        {/* @ts-ignore */}
        {leader?.uid &&
          // leader?.uid !== userInfo?.uid &&
          seletcardHolderType == "Buyers" &&
          (
            <div
              className="d-flex justify-content-around align-items-center py-3 w-100"
              style={{
                background: "white",
              }}
            >
              <div className="opacity-0">
                <TradeButton>
                  <TradeText>Check offer</TradeText>
                </TradeButton>
              </div>
              <div>
                {/* @ts-ignore */}
                {<p className="textOffer"> {`Offer ${leader?.buyerData?.coin} VTE`}</p>}
              </div>

              {userInfo?.uid && <>
                {userInfo?.rewardStatistics?.cards.includes(
                  cardDataValue?.cardName
                ) && leader?.buyerData?.buyerUserId !== userInfo.uid ? <div>
                  <TradeButton
                    style={{
                      marginBottom: "5px"
                    }}
                    onClick={(e) => {
                      getCardDetelist(
                        "Approve",
                        leader?.buyerData
                      );
                    }}
                  >
                    <TradeText>Approve</TradeText>
                  </TradeButton>
                  <TradeButton
                    style={{
                      marginTop: "5px"
                    }}
                    onClick={(e) => {
                      getCardDetelist(
                        "Negotiate",
                        leader?.buyerData
                      );
                    }}
                  >
                    <TradeText>Negotiate</TradeText>
                  </TradeButton>
                </div> :
                  leader?.buyerData?.buyerUserId == userInfo.uid ? <div>
                    <TradeButton
                      onClick={() => {
                        editOurTrade();
                      }}
                    >
                      <TradeText>Edit</TradeText>
                    </TradeButton>

                    <TradeButton
                      style={{
                        marginTop: "5px",
                      }}
                      onClick={() => handleDelete(leader)}
                    >
                      <TradeText>Delete</TradeText>
                    </TradeButton>
                  </div> :
                    <div className="opacity-0">
                      <TradeButton>
                        <TradeText>Check offer</TradeText>
                      </TradeButton>
                    </div>
                }
              </>
              }
              {userInfo?.uid == undefined &&
                <div className="opacity-0">
                  <TradeButton>
                    <TradeText>Check offer</TradeText>
                  </TradeButton>
                </div>
              }
            </div>
          )}

        {/* @ts-ignore */}
        {leader?.uid &&
          // leader?.uid !== userInfo?.uid &&
          seletcardHolderType == "Sellers" && (
            <div
              className="d-flex justify-content-around align-items-center py-3 w-100"
              style={{
                background: "white",
              }}
            >
              <div className="opacity-0">
                <TradeButton>
                  <TradeText>Check offer</TradeText>
                </TradeButton>
              </div>
              <div>
                {/* @ts-ignore */}
                {<p className="textOffer">Asking for {leader?.buyerData?.coin} VTE</p>}
                {<p className="textOffer"
                  style={{
                    fontSize: "10px",
                    color: "#868686"
                  }}
                >Serial No. {leader?.buyerData?.serialNumber}</p>}

              </div>
              {/* <div>
                <TradeButton
                  onClick={(e) => {
                    getCardDetelist(
                      leader?.buyerData?.tradeType,
                      leader?.buyerData
                    );
                  }}
                >
                  <TradeText>Check offer</TradeText>
                </TradeButton>
            </div> */}

              {leader?.uid !== userInfo?.uid ? <div>
                <TradeButton
                  style={{
                    marginBottom: "5px"
                  }}
                  onClick={(e) => {
                    if (!user) {
                      setLoginRedirectMessage("get this offer");
                      setLogin(true);
                    } else {
                      getCardDetelist(
                        "Approve",
                        leader?.buyerData
                      );
                    }
                  }}
                >
                  <TradeText>Approve</TradeText>
                </TradeButton>
                <TradeButton
                  style={{
                    marginTop: "5px"
                  }}
                  onClick={(e) => {
                    if (!user) {
                      setLoginRedirectMessage("get this offer");
                      setLogin(true);
                    } else {
                      getCardDetelist(
                        "Negotiate",
                        leader?.buyerData
                      );
                    }
                  }}
                >
                  <TradeText>Negotiate</TradeText>
                </TradeButton>
              </div> :

                leader?.uid == userInfo?.uid ?
                  <div>
                    <TradeButton
                      style={{
                        marginTop: "5px"
                      }}
                      onClick={(e) => {
                        if (!user) {
                          setLoginRedirectMessage("get this offer");
                          setLogin(true);
                        } else {
                          setOpenModal("saleCardDetails")
                          setTradeDetails(leader?.buyerData)
                        }
                      }}
                    >
                      <TradeText>Edit</TradeText>
                    </TradeButton>
                    <TradeButton
                      style={{
                        marginTop: "5px"
                      }}
                      onClick={(e) => {
                        if (!user) {
                          setLoginRedirectMessage("get this offer");
                          setLogin(true);
                        } else {
                          handleDelete(leader)
                        }
                      }}
                    >
                      <TradeText>Delete</TradeText>
                    </TradeButton>
                  </div> :
                  <div className="opacity-0">
                    <TradeButton>
                      <TradeText>Check offer</TradeText>
                    </TradeButton>
                  </div>
              }
            </div>
          )}
        <>
          {buyerRequest != "" && (
            <BuyerSellerPopup
              openModal={buyerRequest}
              setOpenModal={setBuyerRequest}
              tradeCrad={submitTradeData}
              leader={tradeDetails}
            />
          )}
        </>
      </Component515>

      <>
        <div>
          <Modal
            show={openApprovePopup}
            onHide={() => {
              setOpenApprovePopup(false);
            }}
            size="lg"
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            contentClassName={window.screen.width <= 750 ? "w-100" : "w-50"}
          >
            <div className="d-flex justify-content-end" style={{ zIndex: 100 }}>
              <button
                type="button"
                className="btn-close "
                aria-label="Close"
                onClick={() => {
                  setOpenApprovePopup(false);
                }}
              ></button>
            </div>
            <Modal.Body className="d-flex  justify-content-center align-items-center">
              <p
                className="py-2"
                style={{ fontSize: "20px", textAlign: "center" }}
              >
                You have already opened a negotiation offer
              </p>
            </Modal.Body>
            <div className="d-flex justify-content-center mb-4">
              <Buttons.Primary
                className="mx-2"
                style={{
                  width: "180px",
                  borderRadius: "50px",
                  backgroundColor: "#676767"
                }}
                onClick={async () => {
                  // setPendingVoteShow(false)
                  setOpenApprovePopup(false);
                  navigate(`/profile/trade?tabID=3`);
                }}
              >
                Check negotiation offer
              </Buttons.Primary>

              <Buttons.Primary
                className="mx-2"
                style={{
                  width: "150px",
                  borderRadius: "50px",
                  backgroundColor: "#009344"
                }}
                onClick={async () => {
                  // setPendingVoteShow(false)
                  setOpenApprovePopup(false);
                  setBuyerRequest("sellerDetails");
                }}
              >
                Approve this offer
              </Buttons.Primary>
            </div>
          </Modal>
        </div>
        {openModal == "openCardDetails" && (
          <BuyerPopup
            openModal={openModal}
            setOpenModal={setOpenModal}
            tradeCrad={UpdateBuyerData}
            leader={tradeDetails}
          />
        )}

        {openModal == "saleCardDetails" && (
          <CardSalePopup
            openModal={openModal}
            setOpenModal={setOpenModal}
            tradeCrad={UpdateBuyerData}
            leader={tradeDetails}
          />
        )}
      </>
    </>
  );
};
export default UserCard;
