/** @format */

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled, { createGlobalStyle } from "styled-components";

import "../styles.css";
import { httpsCallable } from "@firebase/functions";
import firebase from "firebase/compat/app";

import { Ratio } from "react-bootstrap";
import NftOneCard from "Pages/NftOneCard";
import UserContext from "Contexts/User";
import CoinsContext from "Contexts/CoinsContext";
import AppContext from "Contexts/AppContext";
import { Other } from "Pages/SingleCoin";
import { useTranslation } from "common/models/Dictionary";
import { db, firestore } from "../../firebase";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { TradeButton, TradeText } from "Components/Users/UserCard";
import BuyerPopup from "Components/Profile/CardTrade/BuyerPopup";
import axios from "axios";
import { showToast } from "App";
import { ToastType } from "Contexts/Notification";
import { confirmDelete, confirmDeleteGift, getUserNameDB } from "common/utils/helper";
import CardSalePopup from "Components/Profile/CardTrade/CardSalePopup";
import { Buttons } from "Components/Atoms/Button/Button";
import Swal from "sweetalert2";
import { texts } from "Components/LoginComponent/texts";

const CenterItem = styled.div`
  background-color: #f2f2f2;
  overflow-x: hidden;
  width: 100%;
  min-height: 80vh;
  // & p {
  //   font-weight: 100;
  //   text-align: center;
  //   padding: 20px;
  //   font-size: 20px;
  //   color: #160133;
  //   text-transform: uppercase;
  // }
  // & strong {
  //   // font-weight: 400;
  //   text-align: center;
  //   // padding: 30px;
  //   font-size: 20px;
  //   color: #160133;

  // }
`;

const ShowCount = styled.div`
    width: 15px;
    height: 15px;
    position: absolute;    
    top: -11px;
    right: -8px;
    font-size: 8px;
    background: #db3636;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
`;
const SummerCard = styled.div`
  width: ${window.screen.width > 767 ? "730px" : "100%"};
  margin: auto;
  display: flex;
  justify-content: center;
`;

const TextStyle = styled.p`
  font: var(--font-style-normal) normal var(--font-weight-normal)
    var(--font-size-12) / var(--line-spacing-14) var(--font-family-poppins);
  color: #807f8b;
  
  // text-align: center;
  letter-spacing: 0.04px;
  text-transform: uppercase;
  opacity: 1;
  // text-decoration: underline;
`;

const DeleteIcon = styled.div`
    font-size:16px;
    color:#d33;
`;

const TradeText2 = styled.p`
  color: #6352e8;
  font-size: 9px;
  // padding: 10px 10px;
  border: none;   
  cursor:pointer;
`;

const SingleCardDetails = () => {
  // const [cards, setCards] = useState(

  //   {
  //     id: 1,
  //     cardType: "COMMON",
  //     cardNo: "CP244",
  //     cardHeader: "INVESTOR",
  //     type: "SUMMER",
  //   },

  // );
  const [CardValue, setCardValue] = useState([]);
  const [backCards, setBackCards] = useState<any>([]);
  const [sameCards, setSameCards] = useState<any>({});
  const [openModal, setOpenModal] = useState<any>("");
  const [openPopupType, setOpenPopupType] = useState<any>("COIN");
  const [addIndex, setAddIndex] = useState<any>("");
  const [winnerCard, setWinnerCard] = useState<any>();
  const [openCardSno, setOpenCardSno] = useState<any>();
  const [buyerCards, setBuyerCard] = useState<any>([]);
  const { user, userInfo } = useContext(UserContext);
  const { leaders } = useContext(CoinsContext);
  const { albumOpen, setAlbumOpen, userTypes, login } = useContext(AppContext);
  const [chosen, setChosen] = useState<string | undefined>();
  const [alreadyAsk, setAlreadyAsk] = useState<any>([]);
  const [allRequestsList, setAllRequestsList] = useState<any>([]);
  const [alreadyGift, setAlreadyGift] = useState<any>([]);
  const [singleCard, setSingleCrad] = useState<any>();
  const [tradeDetails, setTradeDetails] = useState<any>([]);
  const [updateOpenAccount, setUpdateOpenAccount] = useState<any>([]);
  const [onsellOpen, setOnsellOpen] = useState<any>(false);  
  console.log(singleCard,"singleCard")
  const BackSideCard = (value: string | number) => {
    // @ts-ignore
    if (backCards.includes(value)) {
      let allBackCard = [...backCards];
      allBackCard.splice(backCards.indexOf(value), 1);
      setBackCards(allBackCard);
    } else {
      setBackCards([...backCards, value]);
    }
  };

  const [allCardList, setAllCardList] = useState<any>([]);
  const [commenSerialNo, setCommenSerialNo] = useState<any>([]);
  const [singalCardData, setSingalCardData] = useState<any>([]);
  // const []
  // const { singalCardData,setSingalCardData} = useContext(AppContext);

  let params = useParams();
  const translate = useTranslation();
  const { type, id } = params;
  const navigate = useNavigate();

  const pathName= window.location.pathname;
  

  const getNftCard = async () => {
    try {
      const nftGalleryRef = collection(firestore, "nft_gallery");
      const nftGalleryQuery = query(
        nftGalleryRef,
        where("collectionName", "==", type)
      );
      const snapshot = await getDocs(nftGalleryQuery);

      let allcollection = snapshot.docs.map((doc: any) => doc.data());

      const collectionType = allcollection?.map((allCard: any) => {
        return allCard?.setDetails;
      });

      // Further processing with collectionType...
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const getSellandGiftlist = (userId: any) => {  
    axios.get(`/cardTrading/getInitiatedTradeForSaleAndGift/${userId}/${id}`).then((res) => {
      // console.log(res.data.result, "getSellandGiftlist");
      const datalist = res?.data?.result
      const askList = datalist?.find((d: any) => d?.transitionType == "ASK" && (d?.status == "PENDING" || d?.status == "NEGOTIATED"));
      const giftList = datalist?.find((d: any) => d?.transitionType == "GIFT" && (d?.status == "PENDING" || d?.status == "NEGOTIATED"))
      setAlreadyAsk(askList != undefined && askList != "" ? [askList] : [])
      setAlreadyGift(giftList != undefined && giftList != "" ? [giftList] : [])
      setAllRequestsList(datalist)
      console.log(askList, giftList,"get all list giftList")
    }).catch((err) => {
      setOnsellOpen(true)
      console.log(err, "getSellandGiftlist");
    });
  }


  useEffect(() => {
    getNftCard();
    // alllist= getList({cardID:id})

  }, [id]);
  useEffect(() => {
    // setSingalCardData(JSON.parse(localStorage.getItem("singalCardData")));        
    // if (userInfo?.uid) {   
      // @ts-ignore    
      const userID = pathName.includes("followerProfile") ? localStorage.getItem("followerId") : userInfo?.uid 
      getCardData(id, userID)
    // }

  }, [userInfo?.uid]);

  const getCardData = async (cardId: any, userID:any) => { 
    const getCollectionTypeRef = collection(firestore, "cardsDetails");
    const getOneCollection = query(
      getCollectionTypeRef,
      where("cardId", "==", cardId)
    );
    const querySnapshot = await getDocs(getOneCollection);

    let data = {};
    if (!querySnapshot.empty) {      
      const firstDocument = querySnapshot.docs[0];
      // @ts-ignore
      if (userID) {        
        data = { id: firstDocument.id, ...firstDocument.data(), myID: userID};
      } else {        
        data = { id: firstDocument.id, ...firstDocument.data()};
      }
    }
    setSingalCardData(data)
  }
  
  console.log(singalCardData,"singalCardData")


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (singalCardData?.myID) {
          const usersRef = collection(firestore, "users");
          const userQuery = query(
            usersRef,
            where("uid", "==", singalCardData?.myID)
          );
          const snapshot = await getDocs(userQuery);

          const data = snapshot.docs.map((doc) => ({ ...doc.data() }));
          getsamecard(data[0]);
          console.log(data[0], "checkdata");
        }

        if (singalCardData?.myID) {
          getRewardTransactions(singalCardData?.myID);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [singalCardData]);

  const getsamecard = (data: any) => {
    console.log(data, "sameCards i am every time calling");
    var commonCard = {};
    // @ts-ignore
    const allCards = data?.rewardStatistics?.cards;
    allCards?.map((item: any, index: number) => {
      // @ts-ignore
      commonCard = {
        ...commonCard,
        // @ts-ignore
        [item]: commonCard[item] ? commonCard[item] + 1 : 1,
      };
    });
    console.log(commonCard, "commonCard");
    setSameCards(commonCard);
  };

  const getRewardTransactions = async (myID: any) => {
    try {
      const rewardTransactionsRef = collection(
        firestore,
        "reward_transactions"
      );
      const rewardTransactionsQuery = query(
        rewardTransactionsRef,
        where("user", "==", myID)
      );
      const snapshot = await getDocs(rewardTransactionsQuery);
      const initiated: any = (await getListByUserId()) || [];      
      const AllSameCard: any = [];
      snapshot.forEach((cards) => {
        let arrayData: any = initiated.filter((e: any) => {
          if (
            e.serialNumber == cards?.data()?.winData.firstRewardCardSerialNo &&
            !["REJECTED", "APPROVED", "EXPIRED"].includes(e.status)
          ) {
            console.log(e.buyerUserId, "e.buyerUserId");
            return e;
          }
        });              
        console.log(initiated,"arrayData")
        if (cards.data()?.winData?.firstRewardCardId === singalCardData?.cardId) {
          const date = new Date(cards.data()?.transactionTime.seconds * 1000);
          const getMIntedTime = date.toLocaleString();
          AllSameCard.push({
            ...cards.data(),
            getMIntedTime,            
            tradeCardData: arrayData[0],
          });
        }
      });
      console.log(AllSameCard, "AllSameCard")
      
      if (userInfo?.uid && AllSameCard.length < 1) {
        navigate(-1)
      }

      setAllCardList(AllSameCard);
      const getSerialNo :any =[]
      AllSameCard.map((itemvalue:any) => {
        if (itemvalue?.tradeCardData == undefined) {
          getSerialNo.push(itemvalue?.winData?.firstRewardCardSerialNo)
        }
      })
      setCommenSerialNo(getSerialNo)
      console.log(initiated, getSerialNo, "get all snapshots123");
      // console.log(AllSameCard, "AllSameCard");
    } catch (error) {
      console.error("Error fetching reward transactions:", error);
    }
  };
  console.log(allCardList, "AllSameCard");
  useEffect(() => {
    if (openModal == "") {
      setAddIndex("");
    }
    return () => {
      setAddIndex("");
    };
  }, [openModal]);

  const paramsUrl = window.location.pathname;
  console.log(addIndex, "allCardList");
  
  const getListByUserId = async () => {
    // setFollowerListByUserd
    let responce: any = await axios
      // .get(`cardTrading/getInitiatedTrade/${user?.uid}`)    
      // .get(`/cardTrading/getOpenTradeOnTransition/${user?.uid}/${"ASK"}`)
      .get(`/cardTrading/getInitiatedTradeForSaleAndGift/${user?.uid}/${id}`)
      .then((res) => {
        if (res.status === 200) {
          setBuyerCard(res?.data?.result);
          return res?.data?.result;
        }
        console.log(res?.data?.result,"res?.data?.result")
      })
      .catch((error) => {
        return [];
      });

    return responce;
  };
 
  // useEffect(() => {
                
  //   getInfoAboutSell()    
  // }, [userInfo?.uid]);

  useEffect(() => {
    const userIdget = paramsUrl.includes("/profile") ? userInfo?.uid : singalCardData?.myID
    console.log(userIdget,"userIdget")
    if (userIdget) {
      getSellandGiftlist(userIdget);
    }
  }, [userInfo?.uid, singalCardData?.id]);

  const createSellRequrst = async (
    data: any,
    callBack: any,
    isEdit?: any,
    leader?:any,
    getErrorMessage?:any,
  ) => {
    let cardData = { ...singalCardData, sno: [singalCardData?.sno ? singalCardData?.sno[0]:""] };
    let dataPayload = {
      ...data,
      sellerUserId: userInfo?.uid,         
      cardName: singalCardData?.cardName,
      cardId: id,
      serialNumber: commenSerialNo[0],
      sellerUserName:userInfo?.userName,
      lastUpdatedBy: userInfo?.uid, 
      cardData: cardData,
    }    
    console.log(dataPayload,"payloadview")
    await axios.post(`/cardTrading/openTradeAccount`, dataPayload).then((res) => {
      getSellandGiftlist(paramsUrl.includes("/profile") ? userInfo?.uid : singalCardData?.myID);
      getRewardTransactions(paramsUrl.includes("/profile") ? userInfo?.uid : singalCardData?.myID)
      if (data?.transitionType != "GIFT") { 
        showToast(res?.data?.message, ToastType.SUCCESS);
      }
      if (data?.transitionType =="GIFT") {        
        axios.post(`/cardTrading/tradeInitiate`, { ...dataPayload, accountId: res?.data?.result?.id }).then((response) => {
          if (response.status === 200) {
            console.log(response, "get full response")
            if (callBack) callBack();
            showToast(response?.data?.message, ToastType.SUCCESS);               
          }
        }).catch(err => {
          console.log(err)
          const userID = paramsUrl.includes("/profile") ? userInfo?.uid : singalCardData?.myID
          getCardData(id, userID)
          // showToast(err?.response?.data?.message, ToastType.ERROR);
          getErrorMessage(err?.response?.data?.message)
          
        })
      }
      if (res.status === 200) {
        if (callBack) callBack();
      }

    }).catch(err => {
      console.log(err)
      getErrorMessage(err?.response?.data?.message)
      const userID = paramsUrl.includes("/profile") ? userInfo?.uid : singalCardData?.myID
      getCardData(id, userID)
      // showToast(err?.response?.data?.message, ToastType.ERROR);      
    })
  }
  
  const UpdateTradeData = async (
    data: any,
    callBack: any,
    isEdit?: any,
    leaderData?: any,
    getErrorMessage?: any
  ) => {
    let dataPayload = {
      ...data,

      sellerUserId: leaderData?.sellerUserId,
      // buyerUserId: leaderData?.buyerUserId,
      cardId: leaderData?.cardId,
      serialNumber: leaderData?.serialNumber,
      sellerUserName: leaderData?.sellerUserName || "",
      lastUpdatedBy: userInfo?.uid, 
    };
    
    let updateTradeValue = {
      ...data,
      ...updateOpenAccount,
      sellerUserId: updateOpenAccount?.sellerUserId || userInfo?.uid,
      buyerUserId: data?.buyerUserId || userInfo?.uid,
      cardId: updateOpenAccount?.cardId,
      // serialNumber: getPriSerialNo(),
      // serialNumber: winnerCardData?.current?.firstRewardCardSerialNo,
      serialNumber: updateOpenAccount?.serialNumber,
      accountId: updateOpenAccount?.accountId,
      tradeId: updateOpenAccount?.id,
      status: updateOpenAccount?.status,
      lastUpdatedBy: userInfo?.uid,      
    };

    console.log(dataPayload, "alldatadataPayload");
    try {
      await axios
        .put(
          `/cardTrading/updateOpenTrade/${leaderData?.id || leaderData?.accountId
          }`,
          dataPayload
        )
        .then((res) => {
          if (res.status === 200) {
            if (data?.transitionType !== "GIFT") {                            
              if (callBack) callBack();
              getSellandGiftlist(paramsUrl.includes("/profile") ? userInfo?.uid : singalCardData?.myID);
              showToast(res?.data?.message, ToastType.SUCCESS);
              getRewardTransactions(paramsUrl.includes("/profile") ? userInfo?.uid : singalCardData?.myID)
            }
            if (data?.transitionType == "GIFT") {
              axios.put(`/cardTrading/updateTrade/${updateOpenAccount?.id}`, updateTradeValue)
                .then((response) => {
                if (response.status === 200) {
                  console.log(response, "get full response")
                  if (callBack) callBack();
                  showToast(response?.data?.message, ToastType.SUCCESS);  
                  getSellandGiftlist(paramsUrl.includes("/profile") ? userInfo?.uid : singalCardData?.myID);
                  getRewardTransactions(paramsUrl.includes("/profile") ? userInfo?.uid : singalCardData?.myID)
                }
                }).catch(err => {
                  const userID= paramsUrl.includes("/profile") ? userInfo?.uid : singalCardData?.myID
                  getCardData(id, userID)
                  console.log(err)
                // showToast(err?.response?.data?.message, ToastType.ERROR);
                getErrorMessage(err?.response?.data?.message)

              })
            }
          }
        })
        .catch((err) => {
          console.log(err);
          const userID = paramsUrl.includes("/profile") ? userInfo?.uid : singalCardData?.myID
          getCardData(id, userID)
          getErrorMessage(err?.response?.data?.message);
          // showToast(err?.response?.data?.message, ToastType.ERROR);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // const getInfoAboutSell = async () => {        
  //   try {
  //     const response = await axios.get(
  //       `/cardTrading/getOpenTradeOnTransition/${userInfo?.uid}/ASK`
  //     );
  //     let data = response?.data?.result;
  //     console.log(data,"allseller info")
  //     data.find((item: any) => {
  //       if (item.sellerUserId === userInfo?.uid && item.cardId == id){
  //         setTradeDetails(item)
  //       }
  //     })
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     // setIsLoading(false);
  //   }
  // };
  
  const getCardDetelist = (info: any) => {
    try {
      axios
        .get(`/cardTrading/getInitiatedTrade/${userInfo?.uid}/${info?.id}`)
        .then((result: any) => {
          setOpenModal("saleCardDetails");
          setOpenPopupType(info?.tradeCardType);
          setUpdateOpenAccount(result?.data?.result[0])          
        })
        .catch((err: any) => { });
    } catch (error) { }
  };

  const handleDelete = async (tradeInfo: any) => {
    let result;
    if (tradeInfo.transitionType == "GIFT") {
      result = await confirmDeleteGift();
    } else {
      result = await confirmDelete();
    }
    if (result.isConfirmed) {
      // Proceed with the delete action
      console.log('Item deleted', tradeInfo);
      const data = {
        accountId: tradeInfo?.id
      }
      axios.post(`/cardTrading/deleteOpenTrade`, data).then((res) => {        
        showToast(res?.data?.message, ToastType.SUCCESS);
        getSellandGiftlist(paramsUrl.includes("/profile") ? userInfo?.uid : singalCardData?.myID);
        getRewardTransactions(paramsUrl.includes("/profile") ? userInfo?.uid : singalCardData?.myID)
      }).catch((err) => {        
        showToast(err?.response?.data?.message, ToastType.ERROR);        
      });
      // Your delete logic here, e.g., calling an API
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      console.log('Delete action canceled');
    }
  };

  console.log(allCardList?.length ,allRequestsList?.length,"singalCardData")
  return (    
    <> 
      {singalCardData?.length < 1 ?
        <div
          style={{
            position: "fixed",
            backgroundColor: "white",            
            boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            height: "100%",
            display: "flex",
            textAlign: "center",
            justifyContent: "center",
            // top: '0px',
            right: "0px",
            bottom: "0px",
            zIndex: "9999",
            overflow: "hidden",
            width: "100%",
            alignItems: "center",
          }}
        >
          <span
            className="loading"
            style={{
              color: "#7767f7",
              zIndex: "2220px",
              fontSize: "1.5em",
              // marginTop: `${window.screen.width > 767 ? "100px" : "240px"}`
            }}
          >
            {texts.waitForIt}
          </span>
        </div>
        :
        
      <div className="">
      <div className="h-100">
        <CenterItem className="">
          {/* <div className="text-center">
            <p>{cards.cardType}</p>
          </div> */}
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "0px 0px 87px 0px",
              boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
            }}
              >              
            <SummerCard>
              {/* {nftAlbumData?.map((items:any, index:number) => {
              return ( */}
              <div
                className=""
                style={{
                  width: `${window.screen.width < 767 ? "70%" : "38%"}`,
                }}
              >
                {/* @ts-ignore */}

                {/* @ts-ignore */}
                {/* {items?.cards.map((item: any) => {
                      if (item?.cardId == id) {                        
                        return ( */}
                <>
                  <NftOneCard
                    DivClass={singalCardData?.cardType}
                    HeaderText={singalCardData?.cardType}
                    HeaderClass={`${singalCardData?.cardType}_text`}
                    Serie={singalCardData?.setName}
                    BackCardName={singalCardData?.cardName}
                    Rarity={singalCardData?.cardType}
                    CollectionType={singalCardData?.albumName}
                    Quantity={singalCardData?.quantity}
                    holderNo={singalCardData?.noOfCardHolders}
                    cardNo={`${
                      singalCardData?.cardName?.toUpperCase()?.slice(0, 2) +
                      singalCardData?.id?.slice(0, 2)
                    }`}
                    // GeneralSerialNo={singalCardData?.sno && (singalCardData?.sno[0])?.replace(/[0-9]/g, '')}
                    GeneralSerialNo={
                      singalCardData?.albumName?.toUpperCase()?.slice(0, 2) +
                      singalCardData?.setName?.toUpperCase()?.slice(0, 2) +
                      singalCardData?.cardType?.toUpperCase()?.slice(0, 2)
                    }
                    // Disable={"CardDisebal"}
                    // When you pass CardDisebal this name then card is Disable
                    ShowQuantity={`${sameCards[singalCardData?.cardName] || 1}`}
                    cardHeader={`${singalCardData?.cardName}`}
                    // cardNo={`${singalCardData.cardNo}`}
                    id={singalCardData?.cardId}
                    BackSideCard={BackSideCard}
                    // flipCard={backCards == singalCardData.id ? true : false}
                    flipCard={backCards?.includes(singalCardData?.cardId)}
                    ImgUrl={singalCardData?.cardImageUrl || ""}
                    VideoUrl={singalCardData?.cardVideoUrl || ""}
                    darkTheme={true}
                    Hide360Icon={true}
                  />
                </>
              </div>
            </SummerCard>
            <div className="d-flex justify-content-center  pt-2 pb-4">
                  <Other
                    style={{
                      opacity:`${userInfo?.uid ? 1:0}`
                    }}
                onClick={() => {
                  navigate(
                    `${
                    pathName?.includes("followerProfile")
                      ? `/followerProfile/CollectiblesAlbum?collectionName=${singalCardData?.albumName}&cardName=${singalCardData?.cardName}`
                      : `/profile/CollectiblesAlbum?collectionName=${singalCardData?.albumName}&cardName=${singalCardData?.cardName}`
                    }`
                  );
                }}
              >
                {translate("BACK TO COLLECTION")}
              </Other>
            </div>
          </div>
          <div>
            <div>
              <div className="text-center mt-3 mb-1">
                <span
                  style={{
                    color: "black",
                    fontWeight: 500,
                    fontSize: "15px",
                  }}
                >
                  {"Card list".toUpperCase()}
                </span>
              </div>    
              
              {userInfo?.uid && paramsUrl.includes("/followerProfile") &&
                <>
                {alreadyAsk?.length > 0 && alreadyGift?.length > 0 ?
                  <div className="text-center text-danger p-2"> <p>This user has raised sell or gift offer for this card .</p></div>
                  : alreadyAsk?.length > 0 ? <div className="text-center text-danger p-2"> <p>A user has raised sell offer for this card. You can buy it from Wall Of Fame.</p></div> :
                    alreadyGift?.length > 0 ? <div className="text-center text-danger p-2"> <p> A user has raised gift offer for this card.</p></div>:""}
                </>
              }
              {/* {userInfo?.uid && paramsUrl.includes("/followerProfile") && alreadyGift?.length > 0 ? <div className="text-center text-danger"> <p>A user has raised a gift offer for this card.</p></div> :""} */}
              {userInfo?.uid && paramsUrl.includes("/profile") && allCardList?.length > 0 && allCardList?.length == allRequestsList?.length ? <div className="text-center text-danger p-2"><p>For all card sell or gift offer has been created for this card.</p></div> :""}
              {userInfo?.uid && allCardList?.length > 0 && paramsUrl.includes("/profile")&& <div className="d-flex justify-content-center w-100" >
              <div className="d-flex justify-content-around"
                style={{
                  width: `${window.screen.width > 767 ? "400px" : "97%"}`,
                }}
              >
                <div className="d-flex justify-content-center  py-2">
                    <Buttons.Primary
                      disabled={allCardList?.length == allRequestsList?.length}
                    style={{
                      borderRadius: "50px",
                    }}
                    onClick={() => {
                      setOpenModal("buyercards");
                      setOpenPopupType("COIN");
                    }}
                  >
                    Open Sell Offer
                  </Buttons.Primary>
                </div>
                <div className="d-flex justify-content-center  py-2">
                    <Buttons.Primary
                      disabled={allCardList?.length == allRequestsList?.length}
                    style={{
                      borderRadius: "50px",
                    }}
                    onClick={() => {
                      setOpenModal("buyercards");
                      setOpenPopupType("GIFT");
                    }}
                  >
                    Gift a Friend 
                  </Buttons.Primary>
                  </div>
                </div>
              </div>}

              <div
                className="d-flex align-items-center flex-column w-100"
                style={{
                  color: "gray",
                  // color: "#7456ff",
                }}
              >
                {userInfo?.uid &&  allCardList?.map((item: any, index: number) => {                  
                  return (
                    <div
                      key={index + item.getMIntedTime}
                      style={{
                        width: `${window.screen.width > 767 ? "400px" : "97%"}`,
                      }}
                    >
                      <div
                        className="py-2 px-2 my-2 d-flex flex-column"
                        style={{
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          borderRadius: "5px",
                          background: "white",
                        }}
                      >
                        <div className="d-flex justify-content-around">
                        <TextStyle className="py-1">
                          Serial no : {item?.winData?.firstRewardCardSerialNo}
                        </TextStyle>
                        <TextStyle className="py-1">
                          Time : {item?.getMIntedTime}
                        </TextStyle>                        
                        </div>
                        {item?.tradeCardData && <div className="w-100 py-2">
                          <div
                            className="d-flex justify-content-center "
                          >
                            {item?.tradeCardData?.transitionType != "GIFT" && <div className="d-flex justify-content-center align-items-center"
                              style={{
                                width: "30%"
                              }}
                            >                               
                              <div
                                style={{
                                  borderBottom: "1px solid #6352e8",
                                  position: "relative",
                                  cursor: `${item?.tradeCardData?.initiatedCount > 0 ? "pointer" : "no-drop"}`
                                }}
                                onClick={(e) => {
                                  if (item?.tradeCardData?.initiatedCount > 0) {
                                    navigate(`/profile/trade?tabID=3`);
                                  }                                  
                                }}
                              >

                                <ShowCount>
                                  <p>{item?.tradeCardData?.initiatedCount}</p>
                                </ShowCount>
                                <TradeText2
                                  style={{
                                    cursor: `${item?.tradeCardData?.initiatedCount > 0 ? "pointer" : "no-drop"}`,
                                    opacity: `${item?.tradeCardData?.initiatedCount < 1 ? 0.7 : 1}`,
                                  }}
                                >Negotiation offer</TradeText2>

                              </div>
                            </div>}

                            <div className="d-flex justify-content-center align-items-center"
                              style={{
                                width: "20%"
                              }}
                            >
                              <TradeButton
                                onClick={(e) => {
                                  // SendOtherDetails(item?.id);                                
                                  getCardDetelist(item?.tradeCardData)
                                  setTradeDetails(item?.tradeCardData)
                                }}
                              >
                                <TradeText>Edit offer</TradeText>
                              </TradeButton>
                            </div>
                            <div className="d-flex justify-content-center align-items-center"
                              style={{
                                width: "10%"
                              }}
                            >
                              <DeleteIcon
                                onClick={(e) => {
                                  // getCardDetelist(item?.transitionType, item)
                                  handleDelete(item?.tradeCardData)
                                }}
                              >
                                <i className="bi bi-trash"></i>
                              </DeleteIcon>
                            </div>
                          </div>
                        </div>}
                      </div>                      
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <>
            {openModal == "buyercards" && (
              <CardSalePopup
                openModal={openModal}
                setOpenModal={setOpenModal}
                openPopupType={openPopupType}
                tradeCrad={createSellRequrst}
              // leader={item}
              />
            )}

            {openModal == "saleCardDetails" && (
              <CardSalePopup
                openModal={openModal}
                setOpenModal={setOpenModal}
                openPopupType={openPopupType}
                tradeCrad={UpdateTradeData}
                leader={tradeDetails}
              />
            )}
          </>
        </CenterItem>
      </div>
    </div>
      }  
    </>
  );
};

export default SingleCardDetails;
