//@ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// Animation
import { easeQuadIn } from "d3-ease";
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import { useWindowSize } from "../../hooks/useWindowSize";
import UserContext from "Contexts/User";
import {
  CurrentCMPContext,
  CurrentCMPDispatchContext,
} from "Contexts/CurrentCMP";
import AppContext from "Contexts/AppContext";
const CircularProgress = ({ percentage, claimCountFun }) => {
  const { width: w = 0 } = useWindowSize();
  const [startValue, setStartValue] = useState(false);
  const { user, userInfo } = useContext(UserContext);
  const { prevCMP, latestCMP } = useContext(AppContext);
  const [endValue, setEndValue] = useState<Number>(
    userInfo?.voteStatistics?.score % 100 || 0
  );
  const currentCMP = useContext(CurrentCMPContext);
  const setCurrentCMP = useContext(CurrentCMPDispatchContext);

  useEffect(() => {
    let newScore = localStorage.getItem(`${user?.uid}_newScores`) || "0";
    if (newScore != "0") {
      setTimeout(() => {
        let cmpVal = prevCMP.current;
        if (parseFloat(newScore) > parseFloat(cmpVal)) {
          claimCountFun();
          var startVal = 100 - (parseFloat(newScore) - parseFloat(cmpVal));
        } else if (parseFloat(cmpVal) > parseFloat(newScore)) {
          startVal = parseFloat(cmpVal) - parseFloat(newScore);
        } else if (parseFloat(cmpVal) == parseFloat(newScore)) {
          startVal = 0;
        }
        let endVal = startVal + parseFloat(newScore);

        console.log(cmpVal, newScore, "get both value ");
        setEndValue(parseFloat(endVal) || endValue);
        setStartValue(parseFloat(startVal) || 0);
      }, [10]);
      setTimeout(() => {
        localStorage.setItem(`${user?.uid}_newScores`, 0);
        localStorage.setItem(
          `${user?.id}_prevScores`,
          userInfo?.voteStatistics?.score % 100
        );
        setStartValue(false);
        setEndValue(parseFloat(userInfo?.voteStatistics?.score % 100));
        prevCMP.current = percentage;
        latestCMP.current = undefined;
        setCurrentCMP(0);
      }, [4100]);
    }
  }, [percentage]);

  console.log(startValue, endValue, "startValue, endValue");
  console.log(prevCMP.current, latestCMP.current, "latestCMP.current");
  return (
    <div style={{ width: w > 767 ? "98%" : "52%" }}>
      <CircularProgressbarWithChildren
        value={100}
        strokeWidth={1}
        styles={buildStyles({
          pathColor: w > 767 ? "#fff" : "#d9d9d9",
          pathTransition: "none",
          strokeLinecap: "butt",
          trailColor: "transparent",
        })}
      >
        {startValue !== false &&
        localStorage.getItem(`${user?.uid}_newScores`) &&
        localStorage.getItem(`${user?.uid}_newScores`) != "0" ? (
          <AnimatedProgressProvider
            valueStart={parseFloat(startValue)}
            valueEnd={parseFloat(endValue)}
            duration={4}
            easingFunction={easeQuadIn}
          >
            {(value) => {
              const roundedValue = parseFloat(value.toFixed(3));
              console.log(startValue, endValue, value, "getboth value");
              return (
                <>
                  <div>
                    <CircularProgressbarWithChildren
                      value={roundedValue < 0 ? 0 : roundedValue}
                      strokeWidth={w > 767 ? 11 : 13}
                      styles={buildStyles({
                        pathColor: "#6352e8",
                        pathTransition: "none",
                        strokeLinecap: "butt",
                        trailColor: w > 767 ? "transparent" : "#160133",
                      })}
                    >
                      <span
                        style={{
                          color: w > 767 ? "var(--white)" : "var(--black)",
                          fontSize: w > 767 ? "20px" : "16px",
                        }}
                      >
                        {(roundedValue > 100 ? 100 : roundedValue).toFixed(3)}
                        /100
                      </span>
                      <span
                        style={{
                          color:
                            w > 767
                              ? "var(--blue-violet)"
                              : "var(--blue-violet)",
                          fontSize: w > 767 ? "20px" : "16px",
                        }}
                      >
                        CMP
                      </span>
                    </CircularProgressbarWithChildren>
                  </div>
                </>
              );
            }}
          </AnimatedProgressProvider>
        ) : (
          <div>
            <CircularProgressbarWithChildren
              value={
                (endValue ? parseFloat(endValue) : percentage) -
                  localStorage.getItem(`${user?.uid}_newScores`) <
                0
                  ? 0
                  : (parseFloat(endValue) ? parseFloat(endValue) : percentage) -
                    localStorage.getItem(`${user?.uid}_newScores`)
              }
              strokeWidth={w > 767 ? 11 : 13}
              styles={buildStyles({
                pathColor: "#6352e8",
                pathTransition: "none",
                strokeLinecap: "butt",
                trailColor: w > 767 ? "transparent" : "#160133",
              })}
            >
              <span
                style={{
                  color: w > 767 ? "var(--white)" : "var(--black)",
                  fontSize: w > 767 ? "20px" : "16px",
                }}
              >
                {((endValue ? parseFloat(endValue) : percentage) - currentCMP >
                100
                  ? 100
                  : (endValue ? parseFloat(endValue) : percentage) - currentCMP
                ) /* localStorage.getItem(`${user?.uid}_newScores`) */
                  .toFixed(3)}
                /100
              </span>
              <span
                style={{
                  color: w > 767 ? "var(--blue-violet)" : "var(--blue-violet)",
                  fontSize: w > 767 ? "20px" : "16px",
                }}
              >
                CMP
              </span>
            </CircularProgressbarWithChildren>
          </div>
        )}
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default CircularProgress;
