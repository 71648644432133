/** @format */

import React, { useContext, useState, useEffect } from "react";
import { Image, Modal } from "react-bootstrap";
import { useTranslation } from "../../common/models/Dictionary";
import Pairs from "../Pairs/Pairs";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import UserContext from "../../Contexts/User";
import Coins from "../Coins/Coins";
import { calcFavorites } from "../../common/utils/coins";
import AppContext from "../../Contexts/AppContext";
import { HomeContainer } from "../App/App";
import NotificationContext from "../../Contexts/Notification";
import NotLoggedInPopup from "../App/NotLoggedInPopup";
import Quotes from "../Quotes";
import ContentContext from "../../Contexts/ContentContext";
import { useWindowSize } from "../../hooks/useWindowSize";
import InfluencersCarousel from "../Users/InfluencersCarousel";
import { BorderRadius4px } from "../../styledMixins";
import upgrade1 from "../../assets/svg/upgrade1.svg";
import upgrade2 from "../../assets/svg/upgrade2.svg";
import upgrade3 from "../../assets/svg/upgrade3.svg";
import UpgradeCopy from "./Comingsoon";
import PaymentPop from "./PaymentPop";
import { handleSoundClick } from "../../common/utils/SoundClick";
import upgrade from "../../assets/images/upgrade_small.png";
import MoneyUG from "../../assets/images/MoneyUG.png";
import UGBG from "../../assets/images/UGBG.png";
import UGBGM from "../../assets/images/UGBGM.png";
import CardUG from "../../assets/images/CardUG.png";
import GiftUG from "../../assets/images/GiftUG.png";
import MiniUG from "../../assets/images/MiniUG.png";

import XXCOIN from "../../assets/images/XXCOIN.png";
import GiftUg2 from "../../assets/images/GiftUg2.png";
import VoteUg from "../../assets/images/VoteUg.png";
import XXVote from "../../assets/images/XXVote.png";
import VOUCHER from "../../assets/images/VOUCHER.png";
import { useNavigate } from "react-router-dom";
import Upgrade from "./Comingsoon";
import Spinner from "Components/Spinner";

const H2 = styled.h2`
color: #e6d348;
letter-spacing:3px;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: #e6d348;
  text-transform: uppercase;
  
  text-shadow: 0px 1px 3px 0px #5B03FF;
  font-family: 'Lilita One';
`;
const P = styled.p`
  color: #FFF;
// font-family: 'Lilita One';
font-size: 54px;
font-style: normal;
font-weight: 900;
line-height: normal;
text-align:center;
`;
const ExtraText = styled.div`
color: #FFF;
// font-family: 'Lilita One';
font-size: 15px;
font-style: normal;
// font-weight: 900;
line-height: normal;
// text-align:center;
`;


const RoundBox = styled.div` 
border:2px solid #fff,#F4F6FA;
height:${window.screen.width > 767 ? "140px" : "190px"};
border-radius:${window.screen.width > 767 ? "30px" : "0px"};
border: 4px solid var(--White-Gradient, #F4F6FA);
background: linear-gradient(180deg, rgba(82, 99, 184, 0.60) 0%, rgba(178, 102, 245, 0.60) 100%);
display:flex;
flex-wrap:wrap;
& p{
  padding:${window.screen.width > 767 ? " 10px" : "10px 0px 5px 0px"};
font-size: ${window.screen.width > 767 ? " 16.701px" : "12px"};
font-style: normal;
font-weight: 800;
line-height: normal;
text-transform: uppercase;
background: linear-gradient(180deg, #FED167 0%, #F9F6A8 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
text-align: ${window.screen.width > 767 ? "" : "center"};
}

& span {
  padding:${window.screen.width > 767 ? "10px" : "5px 0px 10px 0px"};
  color: #FFF;
font-size: ${window.screen.width > 767 ? " 16px" : "12px"};
font-style: normal;
font-weight: 200;
line-height: normal;
// text-transform: lowercase;
display:inline-block;
text-align: ${window.screen.width > 767 ? "" : "center"};
}
`;

const ButttonDiv = styled.div`

width:290px;
border:4px solid white;
 display: flex;
justify-content: center;
border-radius:50px;
background: linear-gradient(180deg, rgba(82,99,184,1) 0%, rgba(178,102,245,1) 100%);
  animation: zoom-in-zoom-out 1s infinite ;
transition: background 1s;

@keyframes zoom-in-zoom-out {
  0% {
    background: linear-gradient(180deg, rgba(82,99,184,1) 0%, rgba(178,102,245,1) 100%);
    color: #B869FC;
  }
  100% {
   background: linear-gradient(180deg, rgba(212,176,92,1) 0%, rgba(243,236,60,1) 100%);
   color:#DAA636;
  }  
}



  button {
    background:white;
    border:1px solid white;
    border-radius:50px;
    padding:10px;    
    margin:10px 0px;    
    width:260px;
    color: #daa636;
    box-shadow: 0px 3px 10px #1c1c1c57;
& span {
  background: var(--Violet-Gradient, linear-gradient(180deg, #5263B8 0%, #B266F5 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-size: 15px;
// font-style: normal;
font-weight: 500;
line-height: 101.5%;
}
& u {
     -webkit-text-decoration-line: line-through; /* Safari */
   text-decoration-line: line-through; 
   color:black;
   font-weight: 100;
   font-size: 20px;
}
& p {
   font-family:"Lilita One";  
  font-size: 30px;
  font-weight: 500;
line-height: 101.5%;
}
  }


`;




const UpgradePageCopy = () => {
  const translate = useTranslation();
  const { user, userInfo } = useContext(UserContext);
  const { login, firstTimeLogin, setLogin, setLoginRedirectMessage } =
    useContext(AppContext);
  const { showModal } = useContext(NotificationContext);
  const { quotes } = useContext(ContentContext);
  const { width } = useWindowSize();
  const [clicked, setClicked] = useState(false)
  const [comingSoon, setComingSoon] = useState(false)
  const screenWidth = () => (window.screen.width > 979 ? "22%" : "40%");
  const screenHeight = () => (window.screen.width > 979 ? "650px" : "730px");
  const flexType = () => (window.screen.width > 979 ? "end" : "space-around");
  useEffect(() => {
    return () => {
      setClicked(false)
    }
  }, [])
  let navigate = useNavigate();

  const upgradeProfile = (amount: any, extravote: any) => {
    handleSoundClick()
    let payvalue = [amount, "UPGRADE", extravote]
    let PayValuestring = JSON.stringify(payvalue)
    localStorage.setItem("PayAmount", PayValuestring);
    navigate("/votepayment")
  }


  return (
    <>
      {
        userInfo ?
          <>
            <div
              className='p-0 m-0 w-100 d-flex justify-content-center'

              style={{
                backgroundImage: `${window.screen.width > 767 ? `url(${UGBG})` : `url(${UGBGM})`}`,
                backgroundRepeat: `${window.screen.width > 767 ? "no-repeat" : "repeat"}`,
                backgroundPosition: "0 0",
                // backgroundSize: "100% 100%",
                backgroundSize: `${window.screen.width > 767 ? "100% 100%" : "100%"}`,
                // backgroundSize: "cover",
                backgroundAttachment: "fixed",
                minHeight: "80vh",
                // overflow:"scroll"
                overflow: "hidden"
              }}

            >
              <div
                className=''
                style={{
                  // background: "#160133",
                  width: `${window.screen.width > 979 ? "1000px" : "100%"}`,
                }}
              >
                {/* @ts-ignore */}
                {!userInfo?.isUserUpgraded && <H2
                  style={{
                    textAlign: "center",
                    textTransform: 'uppercase',
                    fontSize: `${window.screen.width > 767 ? "4rem" : "2rem"}`,
                  }}
                >
                  {translate("Become a miner")}
                </H2>}
                {/* @ts-ignore */}
                {userInfo?.isUserUpgraded && <H2
                  style={{
                    textAlign: "center",
                    textTransform: 'uppercase',
                    fontSize: `${window.screen.width > 767 ? "4rem" : "2rem"}`,
                  }}
                >
                  {translate("Congratulations")}
                </H2>}
                {/* @ts-ignore */}
                {userInfo?.isUserUpgraded && <H2
                  style={{
                    textAlign: "center",
                    textTransform: 'uppercase',
                    fontSize: `${window.screen.width > 767 ? "4rem" : "2rem"}`,
                  }}
                >
                  {translate("YOU'RE NOW A MINER")}
                </H2>}

                {/* @ts-ignore */}
                {!userInfo?.isUserUpgraded ? <P
                  style={{ fontSize: "18px", fontWeight: "100", marginTop: "10px" }}
                  className="px-3 pt-4  pb-3"
                >
                  {/* Upgrade your account to a full mining account and enjoy the benefits of being a miner. */}
                  {/* <p>Upgrade to Full Mining Account for Enhanced Benefits!</p>

                  <p>Unlock the full potential of your Coin Parliament experience by upgrading to a Full Mining Account. Here's why you should make the switch:</p>
                  <br /> */}
                </P> : null}

                {userInfo?.isUserUpgraded && <ExtraText
                  style={{
                    margin: `${window.screen.width > 767 ? "0px 70px" : "0px 20px"}`,
                  }}
                >
                  <p className="text-center" style={{fontSize:`${window.screen.width > 767 ?"27px":"20px"}`}}> <strong>Congratulations on Upgrading Your Account!</strong></p>
                  <p className="text-center" style={{fontSize:`${window.screen.width > 767 ?"24px":"16px"}`}}> <strong>Maximize Your Efforts for Valuable Rewards!</strong></p>
                  {/* <p> <strong> Upgrade for Valuable Rewards!</strong></p> */}
                  <br />
                  <p><strong>PAX Token : </strong>   As a full mining account holder, you now unlock the power of every vote, minting PAX tokens with real market value directly to your wallet upon completing 100 CMP. Keep voting to reach even more PAX tokens and enhance your earnings.</p>
                  <br />
                  <p><strong>Collectible Cards : </strong>  Enjoy lifetime royalties for your collectible cards. As the first owner, you earn royalties from every future transaction involving them. Your upgraded account ensures you maximize the value and potential of your collectibles.</p>
                  <br />
                  <p> With your upgrade, you're in a prime position to maximize your rewards. Keep voting and stay engaged to unlock the full benefits of your account!</p>
                  <br />
                </ExtraText>   }                     

                { !userInfo?.isUserUpgraded &&
                  <ExtraText
                  style={{
                    margin: `${window.screen.width > 767 ? "0px 70px" : "0px 20px"}`,
                  }}
                >
                  <p className="text-center" style={{fontSize:`${window.screen.width > 767 ?"27px":"20px"}`}}> <strong>Maximize Your Efforts Upgrade for Valuable Rewards!</strong></p>
                  {/* <p> <strong> Upgrade for Valuable Rewards!</strong></p> */}
                  <br />
                  <p><strong>PAX Token : </strong>  Upgrade to a Full Mining Account to unlock the power of every vote, minting PAX tokens with <strong>real market value </strong>  directly to your wallet upon completing 100 CMP.</p>
                  <br />
                  <p><strong>Collectible Cards : </strong>  Elevate your account to enjoy <strong>lifetime royalties</strong> for your collectible cards. As the first owner of these cards, you'll earn royalties from every future transaction involving them. Plus, your collectible cards become decentralized and tradable on the external market, ensuring maximum flexibility and value.</p>
                  <br />
                  <p><strong>VTE Coins : </strong> While you receive VTE game coins regardless of your account status, upgrading unlocks their full potential. These coins have real value and can be used for various purposes within the game, including <strong>purchasing merchandise </strong>, unlocking exclusive features, and more, enhancing your overall gaming experience.</p>
                  <br />
                </ExtraText> 
                }        
                
                {/* @ts-ignore */}
                {userInfo?.isUserUpgraded && <P
                  style={{ fontSize: "18px", fontWeight: "100", marginTop: "10px" }}
                  className="px-3 pt-4  pb-3"
                >
                  Now you can<strong> enjoy the benefits</strong>  of being a miner.
                </P>}

                {!userInfo?.isUserUpgraded &&
                  <>
                    <div
                      className={`${window.screen.width > 767 ? "" : "mt-3"} d-flex justify-content-center mt-3`}
                    >
                      <ButttonDiv className="mt-1">
                        <button
                          onClick={() => {
                            handleSoundClick()
                            upgradeProfile(99, 25)
                            // showModal(<Upgrade />)
                            // setComingSoon(true)
                          }}
                        >
                          <div className='d-flex justify-content-around' >
                            <div

                            >

                              <span
                                style={{
                                  letterSpacing: "4px",
                                  // display:"inline-block",
                                }}
                              >LIMITED
                              </span>
                              <br />
                              <span>TIME OFFER</span>
                            </div>
                            <u>$199</u>
                            <p>$99</p>
                          </div>


                        </button>
                      </ButttonDiv>
                    </div>
                    <div
                      className="text-center mb-4"
                      style={{

                        fontSize: `${window.screen.width > 767 ? "30px" : "20px"}`,
                        fontStyle: "normal",
                        fontWeight: "800",
                        lineHeight: "normal",
                        color: "#dfc650"
                      }}
                    >
                      <p>
                        ONE TIME PAYMENT
                      </p></div>
                  </>
                }

                <div className={`${window.screen.width > 767 ? "" : "justify-content-around "} d-flex w-100 mt-5 upgrade_content mb-4`}>

                  <div className="upgrade_side" style={{
                    width: `${window.screen.width > 767 ? "35%" : "45%"} `,
                  }}>
                    <RoundBox className="innerBlock">
                      <div
                        // className="d-flex justify-content-center align-items-center ml-1 flex-warp"
                        className={`${window.screen.width > 767 ? "justify-content-center align-items-center" : "justify-content-center"} d-flex ml-1`}
                        style={{
                          height: `${window.screen.width > 767 ? "" : "85px"} `,
                          width: `${window.screen.width > 767 ? "30%" : "100%"} `,

                        }}
                      >
                        <img src={MoneyUG} alt="" width={"90px"} />
                      </div>
                      <div className="d-flex justify-content-center flex-column innerContent"
                        style={{
                          width: `${window.screen.width > 767 ? "70%" : "100%"} `,
                        }}
                      >
                        <p>Collectibles Market</p>
                        <span>Use your VTE  for  collectibles trading</span>
                      </div>
                    </RoundBox>

                    <RoundBox className={`${window.screen.width > 767 ? "mt-5" : "mt-3"} innerBlock`}>
                      <div
                        className={`${window.screen.width > 767 ? "justify-content-center align-items-center" : "justify-content-center"} d-flex ml-1`}
                        style={{
                          width: `${window.screen.width > 767 ? "30%" : "100%"} `,
                        }}
                      >
                        <img src={CardUG} alt="" width={"90px"} />
                      </div>
                      <div className="d-flex justify-content-center flex-column innerContent"
                        style={{
                          width: `${window.screen.width > 767 ? "70%" : "100%"} `,
                        }}
                      >
                        <p>Card converter</p>
                        <span>Convert your card to collectible cards</span>
                      </div>
                    </RoundBox>

                  </div>
                  {window.screen.width > 767 && <div

                    className="d-flex justify-content-around align-items-center upgrade_center_side"
                    style={{
                      width: "30%",
                    }}
                  >
                    <img src={upgrade} alt="" width={window.screen.width > 767 ? "400px" : "300px"}
                      style={{
                        marginLeft: "0px", maxWidth: "100%",
                      }}
                    />
                  </div>}
                  <div className="upgrade_side" style={{
                    width: `${window.screen.width > 767 ? "35%" : "45%"} `,
                  }}>
                    <RoundBox className="innerBlock">
                      <div
                        className={`${window.screen.width > 767 ? "justify-content-center align-items-center" : "justify-content-center"} d-flex ml-1`}
                        style={{
                          width: `${window.screen.width > 767 ? "30%" : "100%"} `,
                          height: `${window.screen.width > 767 ? "" : "85px"} `,
                        }}

                      >
                        <img src={GiftUG} alt="" width={"90px"} />
                      </div>
                      <div className="d-flex justify-content-center flex-column innerContent"
                        style={{
                          width: `${window.screen.width > 767 ? "70%" : "100%"} `,
                        }}
                      >
                        <p>Purchases</p>
                        <span>Use your VTE to buy merchandise</span>

                      </div>
                    </RoundBox>

                    <RoundBox className={`${window.screen.width > 767 ? "mt-5" : "mt-3"} innerBlock`}>
                      <div
                        className={`${window.screen.width > 767 ? "justify-content-center align-items-center" : "justify-content-center"} d-flex ml-1`}
                        style={{
                          width: `${window.screen.width > 767 ? "30%" : "100%"} `,
                        }}
                      >
                        <img src={MiniUG} alt="" width={"90px"} />
                      </div>
                      <div className="d-flex justify-content-center flex-column innerContent"
                        style={{
                          width: `${window.screen.width > 767 ? "70%" : "100%"} `,
                        }}>
                        <p>Full mining machine</p>
                        <span>Mine PAX BEP20 token <br />to your external wallet</span>
                      </div>
                    </RoundBox>

                  </div>

                </div>

                {/* @ts-ignore */}
                {/* {!userInfo?.isUserUpgraded &&
            <div
              className='text-center mb-4'              
            >
              <button
                type='button'
                className='btn '
                onClick={() => {    
                  upgradeProfile(99, 0)
                }}
                style={{
                  background:" linear-gradient(to bottom, #6352E8 0%, #3712B3 100%)" ,
                  color: "#fff",
                  width: "250px",                  
                  padding: "5px 0px",
                  boxShadow: "0px 3px 6px #00000029",
                  fontSize: "25px",
                }}
              >
                <p> UPGRADE NOW </p>
                <span style={{ fontSize: "12px" }}>One time Payment of <del>$199</del> $99</span>
              </button>
            </div>} */}
                {/* @ts-ignore */}
                {!userInfo?.isUserUpgraded &&
                  <>
                    <div
                      className={`${window.screen.width > 767 ? "" : "mt-3"} d-flex justify-content-center mt-3`}
                    >
                      <ButttonDiv className="mt-1">
                        <button
                          onClick={() => {
                            handleSoundClick()
                            upgradeProfile(99, 25)
                            // showModal(<Upgrade />)
                            // setComingSoon(true)
                          }}
                        >
                          <div className='d-flex justify-content-around' >
                            <div

                            >

                              <span
                                style={{
                                  letterSpacing: "4px",
                                  // display:"inline-block",
                                }}
                              >LIMITED
                              </span>
                              <br />
                              <span>TIME OFFER</span>
                            </div>
                            <u>$199</u>
                            <p>$99</p>
                          </div>


                        </button>
                      </ButttonDiv>
                    </div>
                    <div
                      className="text-center mb-4"
                      style={{

                        fontSize: `${window.screen.width > 767 ? "30px" : "20px"}`,
                        fontStyle: "normal",
                        fontWeight: "800",
                        lineHeight: "normal",
                        color: "#dfc650"
                      }}
                    >
                      <p>
                        ONE TIME PAYMENT
                      </p></div>
                  </>
                }

                {window.screen.width < 767 && <div

                  className="d-flex justify-content-center align-items-center"
                  style={{
                    width: "100%",
                  }}
                >
                  <img src={upgrade} alt="" width={window.screen.width > 767 ? "400px" : "400px"}
                  // style={{
                  //   marginLeft: "35px"
                  // }}
                  />
                </div>}
                {!userInfo?.isUserUpgraded && <div
                  style={{
                    width: `${window.screen.width > 767 ? "800px" : "100%"}`,
                    border: `${window.screen.width > 767 ? "3px solid #f3f6fa" : "2.5px solid #d4b05c"}`,
                    borderRadius: `${window.screen.width > 767 ? "55px" : "10px"}`,
                    padding: "15px 5px 15px 5px",
                    margin: "auto",
                    background: `${window.screen.width > 767 && "linear-gradient(180deg,rgba(82, 99, 184, 0.30) 0%,rgba(178, 102, 245, 0.30) 100%)"}`,
                  }}
                  className="mb-4 additionBox"
                >
                  <p
                    style={{
                      textAlign: "center",
                      color: "#dbbd54",
                      fontFamily: 'Lilita One',
                      fontSize: "25px",
                      fontStyle: "normal",
                      fontWeight: "900",
                      lineHeight: "normal",
                      // textTransform: `${window.screen.width < 767 ? "uppercase":""}`,
                      letterSpacing: "2px"
                    }}
                  >
                    In addition, you will
                    receive the following gifts
                  </p>
                  <div className="d-flex justify-content-around mt-4 mb-3">
                    <div
                      style={{
                        background: `${window.screen.width < 767 && "linear-gradient(180deg, rgba(82,99,184,1) 0%, rgba(178,102,245,1) 100%)"}`,
                        width: `${window.screen.width > 767 ? "19%" : "28%"}`,
                        borderRadius: "25px",
                      }}
                      className="d-flex justify-content-center align-items-center flex-column  pt-3 pb-3"
                    >
                      <img src={GiftUg2} alt="" width={"40px"} />
                      <p className="text-center mt-2"
                        style={{
                          fontSize: "14px",
                        }}>

                        Merchandise Voucher</p>
                    </div>
                    <div
                      style={{
                        background: `${window.screen.width < 767 && "linear-gradient(180deg, rgba(82,99,184,1) 0%, rgba(178,102,245,1) 100%)"}`,
                        width: `${window.screen.width > 767 ? "19%" : "28%"}`,
                        borderRadius: "25px",


                      }}
                      className="d-flex justify-content-center align-items-center flex-column  pt-3 pb-3"
                    >
                      <img src={VoteUg} alt="" width={"70px"} />
                      <p className="text-center mt-2"
                        style={{
                          fontSize: "14px",
                          padding: "1px"
                        }}>

                        25 <br /> Extra Votes</p>
                    </div>
                    <div
                      style={{

                        background: `${window.screen.width < 767 && "linear-gradient(180deg, rgba(82,99,184,1) 0%, rgba(178,102,245,1) 100%)"}`,
                        width: `${window.screen.width > 767 ? "19%" : "28%"}`,
                        borderRadius: "25px",

                      }}
                      className="d-flex justify-content-center align-items-center flex-column  pt-3 pb-3"
                    >

                      <img src={XXCOIN} alt="" width={"80px"} />
                      <p className="text-center mt-2"
                        style={{
                          fontSize: "14px",
                        }}
                      >500 <br /> VTE</p>

                    </div>
                  </div>
                </div>}
              </div>
            </div>
            <div>
              <Modal
                show={
                  comingSoon
                } onHide={() => { setComingSoon(false) }}
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <div className="d-flex justify-content-end" style={{ zIndex: 100 }}>
                  <button type="button" className="btn-close " aria-label="Close" onClick={() => {

                    setComingSoon(false)

                  }
                  }></button>
                </div>
                <Modal.Body className="d-flex  justify-content-center align-items-center"
                >

                  <p className="py-2" style={{ fontSize: "20px", textAlign: "center" }}>Coming soon</p>

                </Modal.Body>
              </Modal>
            </div>
            {/* {clicked &&
        <PaymentPop
        openPopup={clicked}
        setClicked={setClicked}
      />
      } */}
            {/* <div
        style={{
          backgroundImage: `${window.screen.width > 767 ? `url(${UGBG})` : `url(${UGBGM})`}`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "0 0",
          backgroundSize: "100% 100%",
          backgroundAttachment: "fixed",
          position: "fixed",
          top:120,
          width: "100%",
          height: "800px",
          zIndex: -1
        }}
      >

      </div> */}
          </> :

          <div
            className='d-flex justify-content-center align-items-center'
            style={{ height: "100vh", width: "100vw", color: "white" }}
          >
            <Spinner />
          </div>
      }
    </>

  );
};

export default UpgradePageCopy;
