import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import CoinsContext, { Leader } from "../../Contexts/CoinsContext";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../firebase";
import Leaderboard from "../Leaderboard";
import { throttle } from "lodash";
import styled from "styled-components";
import UserContext from "../../Contexts/User";
import { setChecked } from "../../common/models/User";
import { texts as waittext} from "../LoginComponent/texts";

const Title = styled.h2`
  font: var(--font-style-normal) normal var(--font-weight-normal)
    var(--font-size-14) / 18px var(--font-family-poppins);
  letter-spacing: var(--character-spacing-0);
  color: var(--color-160133);
  opacity: 1;
  text-align: center;

  &:first-letter {
    text-transform: capitalize;
  }
`;

const getLeadersByCoin = httpsCallable(functions, "getLeadersByCoin");

const leadersInstall = async ({
  leaders,
  symbol,
}: {
  leaders: Leader[];
  symbol: string;
}) => {    
  const leadersByCoin = await getLeadersByCoin({ symbol });
  // @ts-ignore
  return (leadersByCoin?.data?.data as unknown as { pct: number; user: string }[])
    .map((leader) => {
      const { user, pct } = leader;
      return { leader: leaders.find((l) => l.userId === user), pct };
    })
    .filter((l) => l.leader)
    .sort((a, b) => {
      return b.pct - a.pct;
    })
    .map((l) => {
      return l.leader as Leader;
    });
};

const install = throttle(leadersInstall, 5000);

const Leaders = ({
  symbol,
  texts,
}: {
  symbol: string;
  texts: { topInfluencers: string };
}) => {
  const { leaders } = useContext(CoinsContext);
  const { user, userInfo } = useContext(UserContext);
  const [coinLeaders, setCoinLeaders] = useState<Leader[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const mountedRef = useRef(true);

  const initLeaders = useCallback(() => {
    if (!mountedRef.current) return null;
    if (leaders.length) {
      install({ leaders, symbol })?.then((c) => {
        setCoinLeaders(c);
      });
    }
  }, [leaders, symbol]);

  useEffect(() => {
    initLeaders();
    return () => {
      mountedRef.current = false;
    };
  }, [initLeaders]);
  
  return coinLeaders.length > 0 ? (
    <>
      {isLoading && <div style={{
        position: 'fixed',
        height: '100%',
        // border: "2px solid red",
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        // top: '0px',
        backgroundColor: "rgba(0,0,0,0.4)",
        right: '0px',
        bottom: '0px',
        zIndex: '9999',
        overflow: 'hidden',
        width: '100%',
        alignItems: 'center',

      }}>
        <span className="loading position-fixed" style={{
          color: "white", zIndex: "2220px", fontSize: '1.5em',
          top: `${window.scrollY + 300}px`,

          // marginTop: `${window.screen.width > 767 ? "50px" : "240px"}`
        }}>
          {waittext.waitForIt}          
        </span>
      </div>}
      <div className="mb-4">
        <Title>{texts.topInfluencers}</Title>
      </div>

      <Leaderboard
        {...{
          leaders: coinLeaders,
          userInfo,
          setChecked: setChecked(leaders, user, setIsLoading),
        }}
      />
    </>
  ) : (
    <></>
  );
};

export default Leaders;
