import React, { useContext, useState } from "react";
import { Leader, follow } from "../../Contexts/CoinsContext";
import { Form, Image } from "react-bootstrap";
import styled from "styled-components";
import { useTranslation } from "../../common/models/Dictionary";
import { importFile } from "../../assets/avatars/Avatars";
import { useNavigate } from "react-router-dom";
import Following from "Components/icons/Following";
import AddFollower from "Components/icons/AddFollower";
import { setChecked, toFollow } from "common/models/User";
import UserContext from "Contexts/User";
import { PoppinsNormalGunsmoke10px } from "styledMixins";
import { texts } from "Components/LoginComponent/texts";
// @ts-ignore
import buttonClick from '../../assets/sounds/buttonClick.mp3';
import useSound from "use-sound";
type PoolMiningCardProps = {
  user: Leader;
};

// const Avatar = styled(Image)`
//   // border: 3px solid var(--blue-violet);
// `;

// const UserData = styled.p`
//   min-height: 17px;
//   margin-top: 10px;
//   letter-spacing: 0.18px;
//   line-height: 15px;
//   white-space: nowrap;
//   margin-bottom: 0;
//   width: 100%;
// `;
const RankValue = styled.span`
  font-size: 10px;
  color: #6352e8;
`;

const Address = styled.p`
  ${PoppinsNormalGunsmoke10px};
  min-height: 17px;
  margin-top: 2px;
  letter-spacing: 0.18px;
  line-height: 15px;
  white-space: nowrap;
  margin-bottom: 0;
  width: 100%;
  display: flex;
  // justify-content: space-between;
`;


const PoolMiningCard = ({ user }: PoolMiningCardProps) => {
  const { userInfo } = useContext(UserContext);
  const translate = useTranslation();
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);
  const { avatar, displayName, status, subscribers, leaders,rank, pct, score, isUserUpgraded } =
    user;
  const checked = !toFollow(userInfo?.leader || [], user?.userId);

  const redirectTab = () => {
    window.localStorage.setItem('followerId', user?.userId)
    // @ts-ignore
    navigate('/followerProfile/mine')
  }

  const handleFollowClick = async (e:any) => {
    e.stopPropagation();
    handleSoundClick()
    if (user) {
      setIsLoading(true);
      try {
        //@ts-ignore
        await follow(user, userInfo, !checked,setIsLoading);
      } catch (error) {
        console.error("Error following/unfollowing", error);
      } finally {
        setIsLoading(false);
      }
    }
  };
  const [handleSoundClick] = useSound(buttonClick);
  const params = window.location.pathname;
  console.log(user,rank,"userRank");
  

  return (
    <div className="user_card mx-auto shadow-sm" style={{ boxShadow: '0px 3px 6px #00000029', width: window.screen.width < 979 ? '322px' : '40%' }} >
      <div className="row hstack">
        <div className="col-2" onClick={e => redirectTab()} style={{ cursor: "pointer" }}>
          <Image
            style={{
              border: `${!isUserUpgraded && " 3px solid var(--blue-violet)"}`,
              boxShadow: `${isUserUpgraded ? "1px 0px 5px #FAE481" : ""}`,
              backgroundColor: `${isUserUpgraded ? "#FAE481" : ""}`,

            }}
            roundedCircle={true}
            // src={avatar?importFile(`./The${avatar}`).default : importFile("./mystery", "png").default}

            src={(avatar && !avatar.includes('http')) ? importFile(`./The${avatar}`).default : !avatar ? importFile("./mystery", "png").default : avatar}
            alt="avatar"
            className="avatar_sm"

          />
        </div>
        <div className="col-5">
          <div >
            <span className="username_sm vstack" style={{ marginBottom: '2px' }}>{displayName}</span>{" "}
            <div style={{display:"flex",justifyContent:"center",gap:"10px",width:"80px"}}>
            <span className="badge_sm rounded-pill vstack " style={{ marginBottom: '2px' }}>{status}</span>
            <span>{user?.rank == 0 ? null : <RankValue>#{user?.rank}</RankValue>}</span>
            </div>
            <Address>
              <span className="mx-1">
                {user?.subscribers} {translate("Followers")}
              </span>
              <span className="mx-1">
                {user?.leaders} {translate("Following")}
              </span>
              {user && (
              <span className="mx-1">
                {user?.influencersScore?.toFixed(2) || 0}&nbsp;Score
              </span>
            )}
              {!user && <span className="mx-1"></span>}
            </Address>
          </div>
        </div>
        <div className="col-5 col-md-3">
          <div className="d-flex align-items-center justify-content-end">
            {/* @ts-ignore */}
            <div className="text_prpl">{user?.refereeScrore ? user?.refereeScrore : 0} CMP</div>
            <div>
              {isLoading && (
                <div style={{
                  position: 'fixed',
                  height: '100%',
                  display: 'flex',
                  textAlign: 'center',
                  justifyContent: 'center',
                  backgroundColor: "rgba(0,0,0,0.4)",
                  right: '0px',
                  bottom: '0px',
                  zIndex: '9999',
                  overflow: 'hidden',
                  width: '100%',
                  alignItems: 'center',
                }}>
                  <span className="loading position-fixed" style={{
                    color: "white",
                    zIndex: "2220",
                    fontSize: '1.5em',
                    top: `${window.scrollY + 300}px`,
                  }}>
                    {texts?.waitForIt}
                  </span>
                </div>
              )}
              <Form.Check.Label
                className=""
                style={{
                  cursor: "pointer",
                  marginLeft: "15px",
                  border: "1px solid rgb(55, 18, 179)",
                  borderRadius: "25px",
                  width: "28px",
                  height: "28px",
                  display: "flex",
                  justifyContent: " center",
                  alignItems: "center",
                  left: "0",
                  position: "relative",
                  flex: "0 0 28px"
                }}
                bsPrefix="label"
                onClick={handleFollowClick}
              >
                {checked == true ? (
                  <Following />
                ) : (
                  <AddFollower />
                )}
              </Form.Check.Label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PoolMiningCard;
