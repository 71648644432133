/** @format */

import React, { useCallback, useContext, useEffect, useState } from "react";
import { useWindowSize } from "../../hooks/useWindowSize";
import styled, { css } from "styled-components";
import lottie from "lottie-web";
import PieChart from "./PieChart";
import Collapse from "./Collapse";
import { useTranslation } from "../../common/models/Dictionary";
import { InputAndButton, PoppinsMediumWhite12px } from "../../styledMixins";
import { Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import UserContext from "../../Contexts/User";
import { functions } from "../../firebase";
import { httpsCallable } from "@firebase/functions";
import { divide, stubFalse } from "lodash";
import { texts } from "../LoginComponent/texts";
// import { claimRewardSound, handleSoundClick, handleSoundWinCmp } from "../../common/utils/SoundClick";
import AppContext from "../../Contexts/AppContext";
import CircularProgress from "../circleProgressbar";
import { Buttons } from "../Atoms/Button/Button";
import Confetti from "../../assets/animation/confetti.json";
import { colors } from "../VoteForm";
import Swal from "sweetalert2";
import { CurrentCMPDispatchContext } from "Contexts/CurrentCMP";
import { showToast } from "App";
import { ToastType } from "Contexts/Notification";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { afterpaxDistributionToUser } from "common/utils/helper";
import useSound from "use-sound";
import Countdown from "react-countdown";

import coinGif from "../../assets/images/coinGif.gif";

// @ts-ignore
import buttonClick from "../../assets/sounds/buttonClick.mp3";

// @ts-ignore
import claimSound from "../../assets/sounds/claimReward.m4a";
// @ts-ignore
import WinCmp from "../../assets/sounds/WinCmp.mp3";
import { Any } from "currency.js";
const Container = styled.div`
  box-shadow: ${(props: { width: number }) =>
    `${props.width > 767}?"0 3px 6px #00000029":"none"`};
  border-radius: 6px;
  opacity: 1;
  padding: ${(props: { width: number }) =>
    `${props.width > 767 ? "12px 28px 41.5px" : "0"}`};
  background: ${(props: { width: number }) =>
    `${props.width > 767 ? "#160133" : "none"}`};
  color: #d4d0f3;
`;

const Title = styled.div`
  color: var(--color-ffffff);
  font: normal normal normal 14px/14px Poppins;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0;
  text-transform: uppercase;
  opacity: 1;
  text-align: center;
`;
// @ts-ignore
const BtnLabel = styled(Form.Check.Label)`
  ${InputAndButton}
  ${PoppinsMediumWhite12px}
  padding: 7.7px 19px;
  justify-content: center;
  align-items: center;
  min-height: 19px;
  letter-spacing: 0;
  white-space: nowrap;
  text-transform: capitalize;
  color: var(--blue-violet);
  cursor: pointer;
`;
const BtnLabelPrimary = styled.button`
  width: 212px;
  font-size: 15px;
  background-color: var(--white);
  color: var(--blue-violet);
  border: none !important;
  position: relative;
  padding: 7.7px 19px;
  justify-content: center;
  align-items: center;
  min-height: 19px;
  letter-spacing: 0;
  white-space: nowrap;
  text-transform: capitalize;
  color: var(--blue-violet);
  cursor: pointer;
  &:disabled {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    opacity: 0.3;
  }
`;

const Option = css`
  border: ${(props: { borderColor: string; selected: boolean }) =>
    `1px solid ${props.borderColor}`};
  background: ${(props: { borderColor: string; selected: boolean }) =>
    props.selected ? colors[0] : colors[1]};
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  box-shadow: rgb(67 47 229) 0px 4px 1px, rgba(0, 0, 0, 0.22) 0px 6px 12px;
  transition: all 0.2s ease;
  &:disabled {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    opacity: 0.3;
  }
`;

const Option0 = styled(Buttons.RadiusTopRight)`
  ${Option};
  flex-direction: column;
  justify-content: center;
  border-radius: 0;
  &:active {
    position: relative;
    top: 2px;
    box-shadow: rgb(67 47 229) 0px 3px 1px, rgba(0, 0, 0, 0.22) 0px 6px 12px;
  }
  &:disabled {
    pointer-events: none;
    cursor: pointer;
  }
  &:hover {
    background: #6352e8;
    color: white;
    box-shadow: rgb(67 47 229) 0px 4px 1px, rgb(170 164 220) 0px 8px 6px;
  }
`;
const Dot = styled.div`
  border-radius: 50%;
  position: absolute;
  font-size: 9px;
  top: -5px;
  right: -5px;
  // text-shadow: -1px 0 1px white;
  font-weight: 300;
  color: white;
  width: 19px;
  height: 19px;
  word-break: break-word;
  padding: 2px;

  background: red;
  // border: 2px solid #666;
  // color: #666;
  text-align: center;
`;
const I = styled.i`
  border-radius: 50%;
  position: absolute;
  font-size: 11px;
  top: 0px;
  right: -20px;

  font-weight: 300;
  color: #6352e8;

  width: 16px;
  height: 16px;

  text-align: center;
`;

const Popuphead = styled.p`
  font-size: 25px;
  font-weight: 600;
  background: linear-gradient(180deg, #fff8a6 29.44%, #ffc00b 73.33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  // text-shadow: 0px 1px 3px #FFCB35;
  font-family: Poppins;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
`;

type MintingProps = {
  score: number;
  setRewardTimer?: any;
  rewardTimer?: any;
  claim?: number;
  setCountShow?: any;
};
const claimReward = httpsCallable(functions, "claimReward");
const paxDistributionOnClaimReward = httpsCallable(
  functions,
  "paxDistributionOnClaimReward"
);
const addPaxTransactionWithPendingStatus = httpsCallable(
  functions,
  "addPaxTransactionWithPendingStatus"
);
const sendNotificationForMintAddress = httpsCallable(
  functions,
  "sendNotificationForMintAddress"
);
const Minting = ({
  score,
  setRewardTimer,
  rewardTimer,
  claim,
  setCountShow,
}: MintingProps) => {
  const { width = 194 } = useWindowSize();
  const translate = useTranslation();
  const { user, userInfo } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const {
    showReward,
    setShowReward,
    rewardExtraVote,
    setRewardExtraVote,
    albumOpen,
    setAlbumOpen,
    inOutReward,
    setInOutReward,
    setHeaderExtraVote,
    showBack,
    setShowBack,
    setIsVirtualCall,
    walletTab,
    setWalletTab,
    setAddPaxWalletPop,
    addPaxWalletPop,
    setExtraVoteModule,
    extraVoteModule,
    upgraeShowOpen,
    setUpgraeShowOpen
  } = useContext(AppContext);
  
  const [resultData, setResultData] = React.useState({});
  const [modalShow, setModalShow] = React.useState(false);
  const [tooltipShow, setTooltipShow] = React.useState(false);
  const [CmpPopupShow, setCmpPopupShow] = React.useState(false);
  const [upgraeShow, setUpgraeShow] = React.useState(false);
  const [pendingVoteShow, setPendingVoteShow] = React.useState(false);
  const [paxDistribution, setPaxDistribution] = React.useState(0);
  const [pendingPax, setPendingPax] = React.useState(0);
  const [pendingValue, setPendingValue] = React.useState(0);
  const [paxAddShow, setPaxAddShow] = React.useState(false);
  const [showCoinMove, setShowCoinMove] = React.useState(true);
  const [PaxToUserOnUpdateAddress, setPaxToUserOnUpdateAddress] = useState<any>();
  const [claimCount, setClaimCount] = React.useState<any>(claim);
  const [claimRewardSoundplay, { pause: claimRewardSoundpause }] =
    useSound(claimSound);
  const [handleSoundWinCmpplay, { pause: handleSoundWinCmppause }] =
    useSound(WinCmp);
  const handleClose = () => setModalShow(false);
  const handleShow = () => {
    setModalShow(true);
    // claimRewardSound.play();
    // claimRewardSoundplay()
    // handleSoundWinCmp.play()
  };
  const [handleSoundClick] = useSound(buttonClick);

  let navigate = useNavigate();
  const setCurrentCMP = useContext(CurrentCMPDispatchContext);
  const handleCmpPopupClose = () => {
    setCmpPopupShow(false);
    if (PaxToUserOnUpdateAddress ) {      
      setUpgraeShow(userInfo?.isUserUpgraded == true || upgraeShowOpen == true ? false : true)
    }
    setCurrentCMP(0);
    localStorage.setItem(`${user?.uid}_newScores`, "0");
  };
  const handleCmpPopupShow = () => {
    setCmpPopupShow(true);
  };

  const handleClosePopup = () => {    
    setUpgraeShow(false);
    setAddPaxWalletPop(false);
  };

  const handleUpgraeShow = () => {
    // handleClick();
    // setUpgraeShow(true);
    // skipHandler();
    claimRewardHandler();
  };
  const handleUpgraeClose = () => {
    setUpgraeShow(false);
    setAddPaxWalletPop(false);
  };

  useEffect(() => {
    //@ts-ignore
    if (localStorage.getItem(`${user?.uid}_newScores`) === "0") {
      setClaimCount(claim);
    }
  }, [localStorage.getItem(`${user?.uid}_newScores`), claim]);

  const claimCountFun = () => {
    if (!claim) return;
    let count: any = claim - 1;
    setClaimCount(count);
  };

  useEffect(() => {
    if (modalShow && CmpPopupShow) {
      setCmpPopupShow(false);
    }
    if (paxDistribution == 0) {
      getPaxDistribution();
    }

    if (userInfo?.uid) {
      axios
        .post("payment/getAllPendingPaxByUserId", {
          userId: userInfo?.uid,
        })
        .then((res: any) => {
          setPendingPax(res?.data?.data?.result?.pendingPaxTotal);
        })
        .catch((err: any) => {
          console.log(err, "resultdata");
        });
    }
  }, [modalShow, CmpPopupShow]);

  useEffect(() => {
    if (
      userInfo?.voteStatistics?.score != undefined &&
      userInfo?.voteStatistics.score != null
    ) {
      const scourValue = `${userInfo?.voteStatistics?.score}`;
      if (scourValue.includes(".")) {
        const decimalPart = Number(scourValue.split(".")[0]);
        const finalvalue = decimalPart % 100;
        const valueWithDoute = finalvalue + "." + scourValue.split(".")[1];
        setPendingValue(100 - Number(valueWithDoute));
      } else {
        const finalvalue = Number(scourValue) % 100;
        setPendingValue(100 - finalvalue);
      }
    }
    return () => {};
  }, [userInfo?.voteStatistics?.score]);

  console.log(pendingValue, "PendingValue");

  const getPaxDistribution = () => {
    axios
      .post(
        "https://us-central1-votetoearn-9d9dd.cloudfunctions.net/getCurrentPaxDistribution",
        {
          data: {},
        }
      )
      .then((res: any) => {
        console.log(res.data.result, "resultdata");
        setPaxDistribution(res?.data?.result?.paxDistribution);
      })
      .catch((err: any) => {
        console.log(err, "resultdata");
      });
  };

  useEffect(() => {
    if (score === 100) {
      setTimeout(() => {
        handleCmpPopupShow();
      }, 4070);
    }
  }, [score, upgraeShow]);

  // useEffect(() => {

  //   if (score === 100 && paxDistribution > 0) {

  //     if (!userInfo?.paxAddress?.address) {
  //       sendNotificationForMintAddress({userId: userInfo?.uid})

  //       addPaxTransactionWithPendingStatus({
  //         userId: userInfo?.uid,
  //         currentPaxValue: Number(paxDistribution),
  //         isUserUpgraded: false,
  //         mintForUserAddress: "",
  //         eligibleForMint: false
  //       }).then((res) => {
  //         console.log(res,"resdata")
  //         // @ts-ignore
  //         if (res?.data?.status) {
  //           afterpaxDistributionToUser(paxDistribution)
  //         }
  //       }).catch(() => { });
  //     }
  //     if (userInfo?.paxAddress?.address) {
  //       paxDistributionOnClaimReward({
  //         userId: userInfo?.uid,
  //         currentPaxValue: Number(paxDistribution),
  //         isUserUpgraded: userInfo?.isUserUpgraded,
  //         mintForUserAddress: userInfo?.paxAddress?.address,
  //         eligibleForMint: true,
  //       }).then((res) => {
  //         console.log(res?.data, "resdata")
  //         // @ts-ignore
  //         if (res?.data?.getResultAfterSentPaxToUser?.status) {
  //           afterpaxDistributionToUser(paxDistribution)
  //         }
  //       }).catch(() => { });
  //     }
  //   }

  // }, [paxDistribution]);

  // console.log(pendingPax,"pendingPax")

  useEffect(() => {
    if (CmpPopupShow) {
      const Animation = lottie.loadAnimation({
        // @ts-ignore
        container: document.querySelector(".Cmp-animation"),
        animationData: Confetti,
        renderer: "html", // "canvas", "html"
        loop: true, // boolean
        autoplay: true, // boolean   ,
      });
      handleSoundWinCmpplay();
    } else {
      handleSoundWinCmppause();
    }
  }, [CmpPopupShow]);

  const [animateButton, setAnimateButton] = useState<boolean>(false);

  const claimRewardHandler = () => {
    setAnimateButton(true);
    setTimeout(() => setAnimateButton(false), 1000);
    handleSoundClick();
    if (claim) {
      setLoading(true);
      setIsVirtualCall(true);
      claimReward({
        uid: user?.uid,
        isVirtual: false,
      })
        .then((result: any) => {
          handleShow();
          setTimeout(() => {
            setShowCoinMove(false);
            claimRewardSoundplay();
          }, 5000);
          setResultData(result);
          setRewardTimer(result);
          setIsVirtualCall(false);
          if (result?.data) {
            // @ts-ignore
            setHeaderExtraVote({
              vote: result?.data!.secondRewardExtraVotes,
              collect: false,
            });
          }
          // claimReward({ uid: user?.uid, isVirtual: true });
          setLoading(false);
        })
        .catch((error) => {
          showToast(error.message, ToastType.ERROR);
        });
    } else {
      // Swal.fire({
      //   title: '',
      //   text: `You still need ${(100 - score).toFixed(2)} CMP to claim your reward.`,
      //   color: 'black',
      //   confirmButtonText: 'Ok',
      //   confirmButtonColor: '#6352e8',
      //   showCloseButton: true,
      //   customClass: {
      //     popup: 'popupStyle',
      //     container: 'popupStyleContainer'
      //   }
      // });
      setPendingVoteShow(true);
    }
  };

  const skipHandler = async () => {
    const mintPaxToAdminOnGiveUp = httpsCallable(
      functions,
      "mintPaxToAdminOnGiveUp"
    );
    const data = {
        userId: userInfo?.uid,
    };
    mintPaxToAdminOnGiveUp(data)
      .then((res) => {
        console.log(res.data, "mintPaxToAdminOnGiveUp");
      })
      .catch((error) => {
        console.log(error, "mintPaxToAdminOnGiveUp");
      });
  }

const sortDataByTimestamp = (data: any) => {
  return data.sort((a: any, b: any) => {            
          if (a?.timestamp?._seconds !== b?.timestamp?._seconds) {
              return b?.timestamp?._seconds - a?.timestamp?._seconds;
          }
          return b?.timestamp?._nanoseconds - a?.timestamp?._nanoseconds;            
  });
};

const ShowPendingAmount = () => {
  axios.post("payment/getAllPendingPaxByUserId", {              
          userId: userInfo?.uid            
  }).then((res: any) => {
      console.log(res?.data?.data?.result,"resdata");
      //res?.data?.data?.result?.paxList[0].currentPaxValue
      if (res?.data) {
          const sortedData = sortDataByTimestamp(res?.data?.data?.result?.paxList);
          const timestamp = sortedData[0];
                    const date = new Date(timestamp.timestamp._seconds * 1000);
                     const endTime = date.getTime() + (24 * 60 * 60 * 1000);
                      // const endTime = date.getTime() + (15 * 61 * 1000);
                     setPaxToUserOnUpdateAddress({
                      ...timestamp,
                      formattedDate: date.toLocaleString(),
                      endTime: endTime,
                      pendingPaxValue: res?.data?.data?.result?.pendingPaxTotal
                 });
      }
  }).catch((err: any) => {
      console.log(err, "resultdata")
  })
}

useEffect(() => {
  if (!userInfo?.isUserUpgraded) {
      ShowPendingAmount()
  }
},[!userInfo?.isUserUpgraded, userInfo?.uid])

  const updateState = () => {
    // setShowReward(1);
    // console.log(inOutReward, "inOutReward2")
    // setInOutReward(1);

    // setCountShow(true)
    setExtraVoteModule(true);
    // @ts-ignore
    setAlbumOpen(resultData?.data?.firstRewardCardCollection);
    // @ts-ignore
    setRewardExtraVote(resultData?.data?.secondRewardExtraVotes);
    // setRewardTimer(resultData); i commented here because i set this when i get result
  };
  console.log("PaxToUserOnUpdateAddress", PaxToUserOnUpdateAddress)

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
    },
    buttonsStyling: false
});

  return (
    <React.Fragment>
      {loading && (
        <Modal
          show={loading}
          backdrop="static"
          centered
          style={{ zIndex: "2200", backgroundColor: "rgba(0, 0, 0, 0.80)" }}
          contentClassName={
            window.screen.width > 767
              ? "card-content modulebackground"
              : "card-contentMob modulebackground"
          }
        >
          <Modal.Body>
            <div
              style={{
                position: "fixed",
                height: "100%",
                display: "flex",
                textAlign: "center",
                justifyContent: "center",
                top: "0px",
                right: "0px",
                bottom: "0px",
                zIndex: "9999",
                overflow: "hidden",
                width: "100%",
                alignItems: "center",
              }}
            >
              <span
                className="loading"
                style={{
                  color: "#7767f7",
                  zIndex: "2220px",
                  fontSize: "1.5em",
                }}
              >
                {texts.waitForIt}
              </span>
            </div>
          </Modal.Body>
        </Modal>
      )}
      <Container
        {...{ width }}
        style={{
          maxWidth: "257.9px",
          minHeight: width < 767 ? "210.9px" : "322.9px",
        }}
      >
        {tooltipShow && (
          <div
            style={{
              display: "relative",
            }}
          >
            <div
              className="newtooltip"
              style={{
                // right: "0%",
                width: `${window.screen.width > 767 ? "25%" : "78%"}`,
                marginLeft: `${window.screen.width > 767 ? "16.50%" : ""}`,
                marginTop: `${window.screen.width > 767 ? "1%" : "10%"}`,
              }}
            >
              {/* <p>Your CMP count</p> */}
              <p
                className="mt-1 lh-base"
                style={{
                  textAlign: "left",
                }}
              >
                This dynamic system amplifies your rewards as you actively vote
                and impact the game.{" "}
              </p>
              <p
                className="mt-3 lh-base"
                style={{
                  textAlign: "left",
                }}
              >
                Watch your CMP grow with every influential vote, unlocking
                Parliament Coins, extra votes, and exclusive cards at key
                milestones.
              </p>
              <p
                className="mt-3 lh-base"
                style={{
                  textAlign: "left",
                }}
              >
                As you climb through user levels, CMP reflects your dedication,
                making your experience in Coin Parliament uniquely rewarding and
                engaging.
              </p>
            </div>
          </div>
        )}
        <div
          className="d-flex justify-content-center align-items-center flex-column"
          style={{ position: "relative", marginTop: width < 767 ? "13px" : "" }}
        >
          <Title
            className="box_title d-md-block text-white d-none mb-4"
            {...{ width }}
          >
            {texts.CPMinting}
          </Title>
          <I
            className="bi bi-info-circle "
            style={{ paddingRight: width < 767 ? "8em" : "" }}
            onMouseDown={(e) => {
              setTooltipShow(false);
            }}
            onMouseUp={(e) => {
              setTooltipShow(true);
            }}
            onMouseEnter={() => setTooltipShow(true)}
            onMouseLeave={() => setTooltipShow(false)}
          ></I>

          <CircularProgress
            percentage={score.toFixed(3) || 0}
            claimCountFun={claimCountFun}
          />
        </div>
        {
          /* width > 767 &&  */ <div
            className="w-100"
            style={{
              display: "flex",
              alignContent: "center",
              paddingLeft: width < 767 ? "2em" : "",
              paddingRight: width < 767 ? "2em" : "",
            }}
          >
            <Option0
              style={{ marginTop: "10px" }}
              {...{
                onClick:
                  !!claim && claim > 0 && !userInfo?.isUserUpgraded
                    ? handleUpgraeShow
                    : claim && !userInfo?.isUserUpgraded
                    ? handleUpgraeShow
                    : claimRewardHandler,
                borderColor: "var(--blue-violet)",
                selected: animateButton,
                className: [
                  "p-3 confetti-button svg-button",
                  animateButton ? "animate" : "",
                ].join(" "),
                disabled:
                  loading || rewardTimer || (claim != undefined && claim < 0),
              }}
            >
              {!!claim && claim > 0 && <Dot>{claimCount}</Dot>}
              {loading
                ? `${texts.CLAIMINGREWARDS}`
                : `${texts.CLAIMYOURREWARDS}`}
            </Option0>
          </div>
        }
      </Container>
      <div>
        {/* reward modal 1 */}
        <Modal
          show={modalShow}
          onHide={handleClose}
          backdrop="static"
          contentClassName={"modulebackground ForBigDiv"}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          animation={false}
          style={{ backgroundColor: "rgba(0,0,0,0.8)", zIndex: "2200" }}
          id="popupid"
        >
          <Modal.Body
            className="d-flex  flex-column  justify-content-between align-items-center"
            style={{
              width: `${window.screen.width > 767 ? "500px" : "100%"}`,
              height: "400px",
            }}
          >
            {!showCoinMove ? (
              <>
                <Popuphead> Congrats!</Popuphead>
                {/* @ts-ignore*/}
                <div className="">
                  <p
                    style={{
                      fontSize: "24px",
                      color: "white",
                      fontWeight: "600",
                    }}
                  >
                    You've won{" "}
                    {
                      // @ts-ignore
                      resultData?.data?.thirdRewardDiamonds
                    }{" "}
                    coins{" "}
                  </p>
                </div>

                <div className="d-flex justify-content-center ">
                  <Buttons.Primary
                    className="mx-2"
                    onClick={() => {
                      // setTimeout(() => {
                      updateState();
                      // }, 1000);
                      claimRewardSoundpause();
                      setTimeout(() => {
                        handleClose();
                        setShowCoinMove(true);
                      }, 50);
                    }}
                  >
                    COLLECT YOUR COIN
                  </Buttons.Primary>
                </div>
              </>
            ) : (
              <>
                <div className="d-flex justify-content-center align-items-center m-auto">
                  {/* <video autoPlay loop muted style={{ width: '40%' }}>
                    <source src={coinVideo} type="video/mp4" />                    
                  </video> */}
                  <img src={coinGif} alt="" width={"200px"} />
                </div>
              </>
            )}
          </Modal.Body>
          {/* </Modal.Footer>       */}
        </Modal>
      </div>

      {/* PopUp for complete 100cmp  */}
      <div>
        <Modal
          show={CmpPopupShow}
          onHide={handleCmpPopupClose}
          backdrop="static"
          contentClassName=""
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="d-flex justify-content-end" style={{ zIndex: 100 }}>
            <button
              type="button"
              className="btn-close "
              aria-label="Close"
              onClick={handleCmpPopupClose}
            ></button>
          </div>
          <Modal.Body className="d-flex  justify-content-center align-items-center">
            <div
              className="Cmp-animation"
              style={{
                height: "150%",
                width: "120%",
                position: "absolute",
                zIndex: "99",
              }}
            />
            <div
              className="py-2 d-flex flex-column  justify-content-center align-items-center"
              style={{ zIndex: "101" }}
            >
              <strong
                className="py-2"
                style={{ fontSize: "20px", textAlign: "center" }}
              >
                Well done, Champ!
              </strong>
              <p
                className="py-2"
                style={{ fontSize: "20px", textAlign: "center" }}
              >
                You've reached your goal.
              </p>
              <p
                className="py-2"
                style={{ fontSize: "14px", textAlign: "center" }}
              >
                Go ahead and claim your reward , You deserve it!
              </p>
            </div>
          </Modal.Body>
          <div
            className="d-flex justify-content-center pb-1 "
            style={{ zIndex: "101" }}
          >
            <Buttons.Primary
              className="mx-2"
              onClick={async () => {
                if (!userInfo?.isUserUpgraded) {
                  handleCmpPopupClose();
                  handleUpgraeShow();
                  setUpgraeShow(false);
                  setAddPaxWalletPop(false);
                  return;
                }
                else {
                  claimRewardHandler();
                  handleCmpPopupClose();
                  setAddPaxWalletPop(false);
                }
              }}
            >
              CLAIM YOUR REWARDS
            </Buttons.Primary>
          </div>
          <div
            className="mx-2 text-center"
            style={{ cursor: "pointer", color: "#6352e8", fontSize: "0.9em" }}
            onClick={() => {
              handleCmpPopupClose();
            }}
          >
            Claim later
          </div>
        </Modal>
      </div>

      {/* For show upgread your account  */}

      <div>
        <Modal
          show={upgraeShow || addPaxWalletPop}
          onHide={() => {
            handleUpgraeClose();
            setUpgraeShowOpen(true);
          }}
          backdrop="static"
          contentClassName=""
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
           <div className="d-flex justify-content-end" style={{ zIndex: 100 }}>
            <button
              type="button"
              className="btn-close "
              aria-label="Close"
              onClick={() => {
                handleClosePopup();
                setUpgraeShowOpen(true)
              }}
            ></button>
          </div>
          <Modal.Body className="d-flex  justify-content-center align-items-center">
            <div
              className="py-2 d-flex flex-column  justify-content-center align-items-center"
              style={{ zIndex: "101" }}
            >
              <strong
                className="py-2"
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Don’t miss it!
              </strong>
              {/* <p
                className="py-2"
                style={{ fontSize: "20px", textAlign: "center" }}
              >
                Upgrade your account to receive your valuable PAX tokens.
                
              </p> */}
              {PaxToUserOnUpdateAddress && (
                <Countdown
                  date={PaxToUserOnUpdateAddress?.endTime} 
                  renderer={({ hours, minutes, seconds,completed}) => {
                    if (completed) {
                      handleClosePopup();
                      return null;
                    }
                      return (
                          <p style={{textAlign:"center", fontSize:"20px"}}>You have received {PaxToUserOnUpdateAddress?.currentPaxValue} PAX. Upgrade your account to claim it : 
                          {hours < 1 ? null : `${hours}:`}
                          {minutes < 10 ? `0${minutes}` : minutes}
                          :{seconds < 10 ? `0${seconds}` : seconds}
                        </p>
                      );
                  }}
                />
                )}
            </div>
          </Modal.Body>
          <div
            className="d-flex justify-content-center pb-3 flex-column align-items-center "
            style={{ zIndex: "101" }}
          >
            <Buttons.Primary
              className="mx-2"
              style={{
                width: "180px",
                background: "#ff9700",
                color: "black",
              }}
              onClick={async () => {
                // handleUpgraeClose();
                setUpgraeShow(false);
                setAddPaxWalletPop(false);
                setUpgraeShowOpen(true);
                navigate("/upgrade");
              }}
            >
              {" "}
              Upgrade
            </Buttons.Primary>
            <div
                        style={{
                            cursor: "pointer",
                            fontSize: "13px",
                            marginTop: 10,
                        }}
                        onClick={async () => {
                            // handleUpgraeClose();
                          setUpgraeShow(false);
                          setAddPaxWalletPop(false);
                          setUpgraeShowOpen(true);
                            swalWithBootstrapButtons.fire({
                                title: "Give up PAX",
                                text: "Are you sure you want to give up pax ?",
                                icon: "warning",
                                showConfirmButton: true,
                                allowOutsideClick: false,
                                allowEscapeKey: false,
                                showCancelButton: true,
                                confirmButtonText: "Yes",
                                cancelButtonText: "No",
                                customClass: {
                                  actions: 'swal2-actions',
                                  confirmButton: 'btn btn-success swal2-confirm',
                                  cancelButton: 'btn btn-danger swal2-cancel',
                              }
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    skipHandler(); 
                                }
                                return result; 
                            });
                        }}
                    >
                        <p>Give Up</p>
                    </div>
          </div>
        </Modal>
      </div>

      {/* show Pending vote to get cmp */}

      <div>
        <Modal
          show={pendingVoteShow}
          onHide={() => {
            setPendingVoteShow(false);
          }}
          backdrop="static"
          contentClassName=""
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="d-flex justify-content-end" style={{ zIndex: 100 }}>
            <button
              type="button"
              className="btn-close "
              aria-label="Close"
              onClick={() => {
                setPendingVoteShow(false);
              }}
            ></button>
          </div>
          <Modal.Body className="d-flex  justify-content-center align-items-center">
            <p
              className="py-2"
              style={{ fontSize: "20px", textAlign: "center" }}
            >
              You still need {pendingValue.toFixed(3)} CMP to claim your reward.
            </p>
          </Modal.Body>
          <div
            className="d-flex justify-content-center pb-3 flex-column align-items-center "
            style={{ zIndex: "101" }}
          >
            <Buttons.Primary
              className="mx-2"
              onClick={async () => {
                setPendingVoteShow(false);
              }}
            >
              ok
            </Buttons.Primary>
          </div>
        </Modal>

        {/* This popup show for add pax data */}
        {/* <div>
          <Modal
            show={addPaxWalletPop}
            onHide={() => {
              setAddPaxWalletPop(false);
            }}
            backdrop="static"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <div className="d-flex justify-content-end" style={{ zIndex: 100 }}>
              <button
                type="button"
                className="btn-close "
                aria-label="Close"
                onClick={() => {
                  setAddPaxWalletPop(false);
                }}
              ></button>
            </div>
            <Modal.Body className="d-flex  justify-content-center align-items-center">
              <p
                className="py-2"
                style={{ fontSize: "20px", textAlign: "center" }}
              >
                Please add pax address to mint your pending pax
              </p>
            </Modal.Body>
            <div className="d-flex justify-content-center">
              <Buttons.Primary
                className="mx-2"
                style={{
                  width: "150px",
                }}
                onClick={async () => {
                  // setPendingVoteShow(false)
                  setAddPaxWalletPop(false);
                  setWalletTab("setting");
                  navigate(`/profile/wallet`);
                }}
              >
                Add Now
              </Buttons.Primary>
            </div>
          </Modal>
        </div> */}
      </div>
    </React.Fragment>
  );
};

export default Minting;
