import React, { useState } from 'react'
import Tabs from '../Tabs'
import TradeHistory from './TradeHistory'
import TradeList from './TradeList'


function CardTrade() {
  const [selectTab, setSelectTab] = useState("Openoffer")
  console.log(selectTab,"selectTab")
  return (
      <>
      {
        // userInfo?.mfa == true &&
        <Tabs
          defaultActiveKey={selectTab}
          activeKey={selectTab}
          id="Trade"
          onSelect={() => {
            setSelectTab(selectTab == "Openoffer" ? "TradeHistory" : "Openoffer")
            // setWalletTab("")
          }}
          tabs={[
            {
              eventKey: "Openoffer",
              title: "Open offer",
              pane: (
                <>
                  {/* <WalletBalance /> */}
                  <TradeList/>
                </>
                // 
              ),
            },
            {
              eventKey: "TradeHistory",
              title: "Trade History",
              pane: (
                <>
                  <TradeHistory
                    selectTab={selectTab}
                  />
                </>
              ),
            },

          ]}
        />}    
      </>
  )
}

export default CardTrade