import { texts } from 'Components/LoginComponent/texts';
import Avatar from 'Components/Users/Avatar';
import { TradeButton, TradeText } from 'Components/Users/UserCard';
import UserContext from 'Contexts/User';
import axios from 'axios';
import { db, firestore } from "../../../firebase";
import { collection, getDocs, limit, query, where } from 'firebase/firestore';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';

function TradeHistory(selectTab:any) {
    const { user, userInfo } = useContext(UserContext);
    const [allTradeList, setAllTradeList] = useState<any>([]); 
    const [isLoading, setIsLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0); 

    
    useEffect(() => {
        let isMounted = true;        
        if (userInfo?.uid && selectTab?.selectTab == "TradeHistory") {            
            fetchData(activeTab);
        }
        return () => {
            isMounted = false;
        };
    }, [userInfo?.uid, selectTab]);   
    

    const convertTime = (updatedAt: any) => {  
        if (updatedAt) {            
            const milliseconds = (updatedAt?._seconds * 1000) + (updatedAt?._nanoseconds / 1000000);        
            const date = moment(milliseconds);   
            console.log(date.format('YYYY-MM-DD HH:mm:ss'), "date.format")
            return date.format('YYYY-MM-DD HH:mm:ss'); // Adjust format as needed
        }
    }

    const getCardNameById = async (cardId: any) => {
        const q = query(
            collection(db, 'cardsDetails'),
            where('cardId', '==', cardId),
            limit(1)
        );
        const usersSnapshot = await getDocs(q);
        const existingUsernames = usersSnapshot.docs.map((doc: any) => doc.data());
        const isUsernameTaken = await existingUsernames[0]?.cardName;
        console.log(isUsernameTaken, "isUsernameTaken")
        return isUsernameTaken
    };


    const sortDataByTimestamp = (data: any) => {
        
        return data.sort((a: any, b: any) => {            
                if (a?.updatedAt?._seconds !== b?.updatedAt?._seconds) {
                    return b?.updatedAt?._seconds - a?.updatedAt?._seconds;
                }
                return b?.updatedAt?._nanoseconds - a?.updatedAt?._nanoseconds;            
        });
    };

    const fetchData = async (index: number) => {
        setActiveTab(index);
        setAllTradeList([])
        const type = index == 0 ? "BID" : index == 1 ? "ASK" : index == 2 ? "GIFT" : "BID"
        setIsLoading(true);
        try {
            const response = await axios.get(
                `/cardTrading/getOpenTrade/${userInfo?.uid}/${type}`
            );
            let data = response?.data?.result;
            console.log(response?.data?.result,"response?.data?.result")
            let listdata: any = [];
            // const updatedArray = await Promise.all(
            //     data?.map(async (item: any) => {
            //         if (item?.status == "APPROVED" || item?.status == "REJECTED" || item?.status == "EXPIRED") {
            //             const newKeyValue = await getCardNameById(item?.cardId);
            //             listdata.push({ ...item, cardName: newKeyValue, });
            //         }
            //     })
            // );
            data?.map(async (item: any) => {
                if (item?.status == "APPROVED" || item?.status == "REJECTED" || item?.status == "EXPIRED") {                    
                    listdata.push({ ...item});
                }
            })
            
            setAllTradeList(sortDataByTimestamp(listdata))
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };


    console.log(allTradeList,"allTradeList")
    const paramsUrl = window.location.pathname;
  return (
      <div>   
          {isLoading && <div style={{
              position: 'fixed',
              height: '68%',
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'center',
              // top: '0px',
              right: '0px',
              bottom: '0px',
              zIndex: '9999',
              overflow: 'hidden',
              width: '100%',
              alignItems: 'center',

          }}>
              <span className="loading" style={{
                  color: "#7767f7", zIndex: "2220px", fontSize: '1.5em',
                  // marginTop: `${window.screen.width > 767 ? "100px" : "240px"}`
              }}>
                  {texts.waitForIt}
              </span>
          </div>}  

          <div className="tradesdiv">
              <div className="trade-buttonsdiv">
                  <button onClick={() => {
                      fetchData(0);

                  }} className={activeTab === 0 ? 'active' : ''}>BUY</button>
                  <button onClick={() => {
                      fetchData(1);

                  }} className={activeTab === 1 ? 'active' : ''}>SELL</button>
                  <button onClick={() => {
                      fetchData(2);

                  }} className={activeTab === 2 ? 'active' : ''}>GIFT</button>
              </div>
              <div className="trade-contentdiv">
                  {activeTab === 0 &&
                      <div>
                          <div
                              className="d-flex align-items-center flex-column w-100"
                              style={{
                                  color: "gray",
                                  // color: "#7456ff",
                              }}>
                              {
                                  userInfo?.uid && allTradeList?.length > 0 ? allTradeList?.map((item: any, index: number) => {

                                      return (
                                          <div
                                              key={index + item.id}
                                              style={{
                                                  width: `${window.screen.width > 767 ? "400px" : "97%"}`
                                              }}
                                          >
                                              <div className="py-2 px-2 my-2 d-flex justify-content-start align-items-center"
                                                  style={{
                                                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                                      borderRadius: "5px",
                                                      background: "white",
                                                  }}
                                              >
                                                  <div
                                                      className=" d-flex justify-content-center align-items-center"
                                                      style={{
                                                          borderRadius: "50px",
                                                          width: "25%",
                                                          
                                                      }}
                                                      onClick={e => {



                                                      }}>
                                                        <div
                                                        style={
                                                            {
                                                                width: "43px",
                                                          height: "43px",
                                                            }
                                                        }
                                                        >
                                                      <Avatar url={""} />
                                                        </div>
                                                  </div>
                                                  <div className='d-flex flex-column'>
                                                      <p>{item?.cardName}</p>
                                                      {/* <div className='d-flex justify-content-between'> */}
                                                      <p>{item?.coin} VTE</p>
                                                      {/* </div> */}
                                                      {/* <p >Buyer Name : {item?.sallerUserName}</p> */}
                                                      {item?.status != "EXPIRED" && item?.sellerUserName && <p>Card bought from {item?.sellerUserName} </p>}
                                                      <p>{item?.status == "EXPIRED" ? "Created Time":"Transaction Time"} : {item?.status != "EXPIRED" ? convertTime(item?.updatedAt || item?.timestamp) : convertTime(item?.timestamp)}</p>
                                                      <p>Status : {item?.status}</p>
                                                  </div>
                                                  <div>

                                                  </div>
                                              </div>
                                          </div>
                                      )
                                  }
                                  ) : <>
                                      {allTradeList?.length < 1 && !isLoading && <p>Data not Found</p>}
                                  </>
                              }

                          </div>
                      </div>}
                  {activeTab === 1 &&
                      <div>
                          <div
                              className="d-flex align-items-center flex-column w-100"
                              style={{
                                  color: "gray",
                                  // color: "#7456ff",
                              }}>
                              {
                                  userInfo?.uid && allTradeList?.length > 0 ? allTradeList?.map((item: any, index: number) => {

                                      return (
                                          <div
                                              key={index + item.id}
                                              style={{
                                                  width: `${window.screen.width > 767 ? "400px" : "97%"}`
                                              }}
                                          >
                                              <div className="py-2 px-2 my-2 d-flex justify-content-start align-items-center"
                                                  style={{
                                                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                                      borderRadius: "5px",
                                                      background: "white",
                                                  }}
                                              >
                                                  <div
                                                      className=" d-flex justify-content-center align-items-center"
                                                      style={{
                                                          borderRadius: "50px",
                                                          width: "25%",
                                                                                                              
                                                      }}

                                                      onClick={e => {

                                                      }}>
                                                        <div
                                                        style={
                                                            {
                                                                width: "43px",
                                                          height: "43px",
                                                            }
                                                        }
                                                        >
                                                      <Avatar url={""} />
                                                        </div>
                                                  </div>
                                                  <div className='d-flex flex-column'>
                                                      <p>{item?.cardName}</p>
                                                      {/* <div className='d-flex justify-content-between'> */}
                                                      <p>{item?.coin} VTE</p>
                                                      {/* </div> */}
                                                      {/* <p >Buyer Name : {item?.sallerUserName}</p> */}
                                                      {item?.status != "EXPIRED" && item?.buyerUserName && <p>Card sold to {item?.buyerUserName} </p>}
                                                      <p>{item?.status == "EXPIRED" ? "Created Time" : "Transaction Time"} : {item?.status != "EXPIRED" ? convertTime(item?.updatedAt || item?.timestamp) : convertTime(item?.timestamp)}</p>
                                                      <p>Status : {item?.status}</p>
                                                  </div>
                                                  <div>

                                                  </div>
                                              </div>
                                          </div>
                                      )
                                  }
                                  ) : <>
                                      {allTradeList?.length < 1 && !isLoading && <p>Data not Found</p>}
                                  </>
                              }

                          </div>
                      </div>}
                  {activeTab === 2 &&
                      <div>
                          <div
                              className="d-flex align-items-center flex-column w-100"
                              style={{
                                  color: "gray",
                                  // color: "#7456ff",
                              }}>
                              {
                                  userInfo?.uid && allTradeList?.length > 0 ? allTradeList?.map((item: any, index: number) => {

                                      return (
                                          <div
                                              key={index + item.id}
                                              style={{
                                                  width: `${window.screen.width > 767 ? "400px" : "97%"}`
                                              }}
                                          >
                                              <div className="py-2 px-2 my-2 d-flex justify-content-start align-items-center"
                                                  style={{
                                                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                                      borderRadius: "5px",
                                                      background: "white",
                                                  }}
                                              >
                                                  <div
                                                      className=" d-flex justify-content-center align-items-center"
                                                      style={{
                                                          borderRadius: "50px",
                                                          width: "25%",
                                                          
                                                      }}
                                                      onClick={e => {



                                                      }}>
                                                        <div
                                                        style={
                                                            {
                                                                width: "43px",
                                                          height: "43px",
                                                            }
                                                        }
                                                        >
                                                      <Avatar url={""} />
                                                        </div>
                                                  </div>
                                                  <div className='d-flex flex-column'>
                                                      <p>{item?.cardName}</p>
                                                      {/* <div className='d-flex justify-content-between'> */}
                                                      {/* <p>{item?.coin} VTE</p> */}                                                      
                                                      {/* </div> */}
                                                      {/* <p >Buyer Name : {item?.sallerUserName}</p> */}
                                                      {item?.status != "EXPIRED" && item?.sellerUserId == userInfo?.uid ? <p> Gift send to {item?.buyerUserName} </p> : <p>Gift received from {item?.sellerUserName}</p>}
                                                      <p>{item?.status == "EXPIRED" ? "Created Time" : "Transaction Time"} : {item?.status != "EXPIRED" ? convertTime(item?.updatedAt || item?.timestamp) : convertTime(item?.updatedAt )}</p>
                                                      <p>Status : {item?.status}</p>
                                                  </div>
                                                  <div>

                                                  </div>
                                              </div>
                                          </div>
                                      )
                                  }
                                  ) : <>
                                      {allTradeList?.length < 1 && !isLoading && <p>Data not Found</p>}
                                  </>
                              }

                          </div>
                      </div>}
              </div>
              </div>                  
    </div>
  )
}

export default TradeHistory