import React from "react";

const copyShare = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.38"
      height="25.972"
      viewBox="0 0 22.38 25.972"
    >
      <path
        id="copyShare"
        d="M16.523.375V2.751H2.376V19.274H0V2.751A2.294,2.294,0,0,1,.691,1.065,2.29,2.29,0,0,1,2.376.375H16.523Zm3.537,4.752a2.228,2.228,0,0,1,1.63.691,2.233,2.233,0,0,1,.691,1.63V23.971a2.294,2.294,0,0,1-.691,1.686,2.227,2.227,0,0,1-1.63.69H7.073A2.359,2.359,0,0,1,4.7,23.971V7.448a2.228,2.228,0,0,1,.691-1.63,2.3,2.3,0,0,1,1.685-.691H20.059Zm0,18.843V7.448H7.073V23.971H20.059Z"
        transform="translate(0 -0.375)"
        fill="#6352e9"
      />
    </svg>
  );
};

export default copyShare;
